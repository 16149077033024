import Modal from "@mui/material/Modal";
import { Formik, Form } from "formik";
import { useState, useEffect } from "react";
import { Card } from "@mui/material";
import FormField from "layouts/pages/users/new-user/components/FormField";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import socket from './socket'
import DeviceQR from "./DeviceManagmentSteps/DeviceQR";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 470,
  maxHeight: "500px",
  bgcolor: "#ffffff",
  border: "2px solid #FFFFFF",
  borderRadius: "5px",
  boxShadow: 24,
  scrollBehavior: "smooth",
};

const EditDevice = ({ open, setOpen, device, api, setUpdated }) => {
  const [label, setLabel] = useState(device.label);
  const [showQrCode, setShowQrCode] = useState(false);
  const handleClose = () => setOpen(false);
	const activateDevice = async (e) => {
		e.preventDefault();
		setShowQrCode(true);
	}
  const handleSubmit = async (e) => {
		e.preventDefault();
		const [response, processAlert] = await Promise.all([
      api.patch(`/devices`, { deviceId: device.id, label: label }),
      Swal.fire({
        icon: "info",
        title: "",
        text: "Updating the device ...",
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
      }),
		]);
		if (response.status === 200) {
			Swal.fire({
        icon: "success",
        title: "",
        text: "Device updated successfully",
        timer: 1500,
        showConfirmButton: false,
      });
			setUpdated(true);
		} else {
			Swal.fire({
        icon: "error",
        title: "",
        text: "Device could not be updated, Please try again",
        timer: 2500,
        showConfirmButton: false,
      });
		}
		setOpen(false);
	}

  useEffect(() => {
		if (!open) {
			setShowQrCode(false);
			setUpdated(false);
		} else {
			setUpdated(true);
		}
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!showQrCode ?
			(<SoftBox sx={style}>
			{
				!device.status ? 
				(<SoftBox textAlign="center" marginTop={2} marginBottom={2}>
					<SoftTypography variant="button" fontWeight="regular" color="text">
						Your device is disabled, to resync it please click the button below <br/>
					</SoftTypography>
						<SoftButton
							variant="gradient"
							color="info"
							size="small"
							onClick={activateDevice}
						>
							Resync the device
						</SoftButton>
					</SoftBox>) :
					(<SoftBox>
						<SoftBox
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<SoftTypography p={2} sx={{ fontSize: "25px" }}>
							Edit Device
						</SoftTypography>
					</SoftBox>
					<Formik>
						<Form id={'device-edit'} autoComplete="off">
							<Card
								sx={{
									overflow: "auto",
									width: "100%",
									height: "auto",
									maxHeight: "420px",
								}}
							>
								<SoftBox sx={{ padding: "0px 10px 20px 10px" }} >
									<FormField
										type="string"
										label="Device label"
										name="Device label"
										onChange={(e) => setLabel(e.target.value)}
										value={label}
										placeholder="Test Device"
									/>
									<SoftBox m={2} display="flex" justifyContent="center" gap={2}>
										<SoftButton
											variant="outlined"
											color="error"
											onClick={handleClose}
										>
											Cancel
										</SoftButton>
										<SoftButton
											variant="outlined"
											color="dark"
											onClick={handleSubmit}
											type="submit"
										>
											Confirm
										</SoftButton>
									</SoftBox>
								</SoftBox>
							</Card>
						</Form>
					</Formik>
					</SoftBox>)
				}
      </SoftBox>) :
			(<SoftBox sx={style}>
				<DeviceQR socket={socket} deviceId={device.id} setOpen={setOpen} setShowQrCode={setShowQrCode} />
			</SoftBox>)}
    </Modal>
  );
};

export default EditDevice;
