import React from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Paper, createStyles } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

// import { deepOrange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageBlue: {
      position: "relative",
      marginTop: "7px",
      marginLeft: "20px",
      //   marginBottom: "5px",
      padding: "10px",
      backgroundColor: "#7AE3FB",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .87em 'Open Sans', sans-serif",
      border: "1px solid #7AE3FB",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #7AE3FB",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #7AE3FB",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginTop: "7px",
      //   marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#959faf",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      color: "#ffffff",
      font: "400 .87em 'Open Sans', sans-serif",
      border: "1px solid #959faf",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #959faf",
        borderLeft: "15px solid transparent",
			borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #959faf",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      paddingBottom: 1,
      fontSize: "0.80em",
    },
    messageTimeStampRight: {
      padding: "2px",
      position: "absolute",
      fontSize: ".60em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "-3px",
      right: "5px",
      //   backgroundColor: "red",
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "";
  const classes = useStyles();
  return (
    <>
      <div className={classes.messageRow}>
        <div className={classes.messageBlue}>
          <p className={classes.messageContent}>{message}</p>
          <div className={classes.messageTimeStampRight}>{timestamp}</div>
        </div>
      </div>
    </>
  );
};
//avatarが右にあるメッセージ（自分）
export const MessageRight = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageOrange}>
        <p className={classes.messageContent}>{message}</p>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};
