/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import ContentLoader, { Facebook } from "react-content-loader";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "../../examples/Tables/Table";
import axios from "axios";

// Reports page components
import Reviews from "../../layouts/pages/users/reports/components/Reviews";

// Data
import tableData from "../../layouts/pages/users/reports/data/tableData";
import MyLoader from "./Loader";
import ListLoader from "./ListLoader";
import createAPI from "api";

// const BASE_URL = process.env.REACT_APP_ENV_URL;

function MainDashboard() {
  const { columns, rows } = tableData;
  // const api = axios.create({
  //   baseURL: `${BASE_URL}`,
  // });
  const { user, isAuthenticated, isLoading } = useAuth0();

  // ComplexStatisticsCard dropdown menu state
  const [usersActiveMenu, setUsersActiveMenu] = useState(null);
  const [clickEventsMenu, setClickEventsMenu] = useState(null);
  const [purchasesMenu, setPurchasesMenu] = useState(null);
  const [likesMenu, setLikesMenu] = useState(null);

  const [devicesCount, setDevicesCount] = useState("---");
  const [messagesCount, setMessagesCount] = useState("---");
  const [contactsCount, setContactsCount] = useState("---");
  const [campaigns, setCampaigns] = useState(null);

  // ComplexStatisticsCard dropdown menu handlers
  const openUsersActiveMenu = (event) => setUsersActiveMenu(event.currentTarget);
  const closeUsersActiveMenu = () => setUsersActiveMenu(null);
  const openClickEventsMenu = (event) => setClickEventsMenu(event.currentTarget);
  const closeClickEventsMenu = () => setClickEventsMenu(null);
  const openPurchasesMenu = (event) => setPurchasesMenu(event.currentTarget);
  const closePurchasesMenu = () => setPurchasesMenu(null);
  const openLikesMenu = (event) => setLikesMenu(event.currentTarget);
  const closeLikesMenu = () => setLikesMenu(null);

	const api = createAPI();
  const getDashboardDetails = async () => {
    const res = await api.get("/dashboard/" + user.email);
    setContactsCount(res.data.contactsCount);
    setMessagesCount(res.data.messagesCount);
    setDevicesCount(res.data.devicesCount);
    setCampaigns(res.data.campaigns);
  };

  useEffect(() => {
		const createUser = async () => {
			const userexists = await api.get(`/users/${user.email}`);
			if (!userexists.data) {
				await api.post(`/users/${user.email}`);
			}
			getDashboardDetails(user.email);
		}
    if (isAuthenticated) {
			createUser();
		}
  }, [user]);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Action</MenuItem>
      <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  {contactsCount === "---" && <MyLoader />}
                  {contactsCount !== "---" && (
                    <ComplexStatisticsCard
                      icon="account_circle"
                      count={{ number: contactsCount, label: "Contacts" }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  {messagesCount === "---" && <MyLoader />}
                  {messagesCount !== "---" && (
                    <ComplexStatisticsCard
                      icon="comment"
                      count={{ number: messagesCount, label: "Messages" }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  {devicesCount === "---" && <MyLoader />}
                  {devicesCount !== "---" && (
                    <ComplexStatisticsCard
                      icon="phone_android"
                      count={{ number: devicesCount, label: "Devices" }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
              {campaigns == null && <ListLoader />}
              {campaigns != null && <Reviews campaigns={campaigns} />}
            </Grid>
          </Grid>
        </SoftBox>
        {/* <Table columns={columns} rows={rows} /> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MainDashboard;
