import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";

import { useAuth0 } from "@auth0/auth0-react";
import createAPI from "api";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";

// Action celll for campaigns list
function CampaignActionCell({ campaign, campaignsTable, setCampaignsTable }) {
	const api = createAPI();
  const deleteDevice = async () => {
    const alert = await Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    });
    if (alert.isConfirmed) {
      const [response, process] = await Promise.all([
				api.delete('campaigns/' + campaign.id),
        Swal.fire({
          icon: "info",
          title: "",
          text: "Deleting campaign ... ",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        }),
      ]);
      if (response.status === 200 || response.status === 204) {
        await Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your campaign has been deleted.",
          showConfirmButton: false,
          timer: 1000,
        });
        setCampaignsTable((prev) => {
          let filterValues = [];
          if (prev.hasOwnProperty("rows")) {
            let rows = prev.rows;
            filterValues = rows.filter((data) => {
              // fix this condition
              return !(data.id === campaign.id);
            });
          }
          if (filterValues.length === 0) {
            filterValues = [
              {
                device: "There are no campaigns available",
              },
            ];
          }
          return { ...prev, rows: [...filterValues] };
        });
        // const res = await api.delete("/campaigns/delete-campaign", {
        //   data: { campaignId: campaign.campaignId },
        // });
        // console.log(`Response of delete one campaign: ${res.data}`);
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Failed to delete the campaign ! Please try again",
          timer: 2600,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <SoftBox display="flex" alignItems="center">
      {/* <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Preview product" placement="top">
        <Icon>visibility</Icon>
        </Tooltip>
      </SoftTypography> */}
      <SoftBox mx={2}>
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Edit product" placement="top">
            <Icon
              onClick={async () => {
                await Swal.fire({
                  icon: "info",
                  title: "",
                  text: "We are still working on it...",
                  timer: 2000,
                  showConfirmButton: false,
                });
              }}
            >
              edit
            </Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title="Delete product" placement="left">
          <Icon onClick={deleteDevice}>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

function CampaignsList() {
	const api = createAPI();
  const { user, isAuthenticated, isLoading } = useAuth0();
	const initialState = {
		columns: [
			{ Header: "Campaign name", accessor: "name", width: "20%" },
			{ Header: "Campaign device", accessor: "device", width: "20%" },
			{ Header: "Campaign data", accessor: "data", width: "20%" },
			{ Header: "Campaign media", accessor: "media", width: "20%" },
			{ Header: "Campaign actions", accessor: "actions", width: "20%" },
		],
		rows: [
			{
				data: <CircularProgress color="inherit" />,
			},
		],
	};
  const [campaignsTable, setCampaignsTable] = useState(initialState);
  const [campaigns, setcampaigns] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  const fetchCampaignsData = async () => {
    const response = await api.get("/campaigns/user/" + user.email);
    let arr = [];
    response.data.map((data) => {
      const obj = {
        id: data.id,
        name: data.name,
        device: data.deviceId,
        data: data.content,
				media: data.picture || "-",
        actions: (
          <CampaignActionCell
            campaign={data}
            campaignsTable={campaignsTable}
            setCampaignsTable={setCampaignsTable}
          />
        ),
      };
      arr.push(obj);
    });
    if (response.data.length === 0) {
      arr = [
        {
          device: "There are no campaigns available ",
        },
      ];
    }
    setCampaignsTable((prev) => {
      return { ...prev, rows: [...arr] };
    });
  };

  useEffect(() => {
    if (!isLoading) fetchCampaignsData();
    console.log("campaigns table -----> ", campaignsTable);
  }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={3}
            >
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Campaigns List
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  To view the progress of your campaign, please go to the
                  dashboard
                </SoftTypography>
              </SoftBox>
              <Stack spacing={1} direction="row">
                <Link to="/campaigns/add-campaign">
                  <SoftButton variant="gradient" color="info" size="small">
                    New Campaign
                  </SoftButton>
                </Link>
              </Stack>
            </SoftBox>

            <DataTable table={campaignsTable} />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CampaignsList;
