import React from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

function Tips() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
			<SoftBox mb='10px'>
				<SoftTypography variant='h3'>How to avoid being blocked on WhatsApp?</SoftTypography>
				<SoftTypography>Blocking on WhatsApp is a reality, and it's crucial to navigate the platform's policies carefully to prevent any disruptions to your messaging activities. Here are some insights and guidelines to help you avoid being blocked on WhatsApp while using SwiftBroadcastPro:</SoftTypography>

				<SoftTypography variant='h4' mt='10px'>Understanding WhatsApp Policies:</SoftTypography>
				<SoftTypography>Blocking on WhatsApp can occur due to a variety of factors, mainly associated with violating policies on bulk messaging. SwiftBroadcastPro users should be aware of these key considerations:</SoftTypography>

				<ul style={{paddingLeft: '20px'}}>
					<li>
						<SoftTypography variant='h5'>Bulk Messaging Policies:</SoftTypography>
						<SoftTypography>WhatsApp strictly prohibits users from sending unsolicited messages in bulk. Ensure your messages align with the recipient's interests to prevent being marked as spam.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>Anti-Spam Laws:</SoftTypography>
						<SoftTypography>Mass messaging on WhatsApp may violate anti-spam laws in certain countries. Always adhere to regional regulations when sending messages in bulk.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>Relevance of Messages:</SoftTypography>
						<SoftTypography>Irrelevant or non-useful messages may be perceived as spam, leading to messages being ignored or reported.</SoftTypography>
					</li>
				</ul>

				<SoftTypography variant='h4' mt='10px'>Additional Considerations:</SoftTypography>
				<SoftTypography>In the SwiftBroadcastPro environment, there are specific details to bear in mind to minimize the risk of being flagged as "suspicious" by WhatsApp:</SoftTypography>
				
				<ul style={{paddingLeft: '20px'}}>
					<li>
						<SoftTypography variant='h5'>WhatsApp Business Scrutiny:</SoftTypography>
						<SoftTypography>Activities on the WhatsApp Business application are under tighter scrutiny. Even seemingly innocuous actions, like sending messages or adding a linked device, can trigger a ban.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>IP Address Caution:</SoftTypography>
						<SoftTypography>Accounts registered from the same IP address as a previously blocked/suspended account are more likely to be marked as "suspicious."</SoftTypography>
					</li>
				</ul>
				<SoftTypography variant='h4' mt='10px'>Mass Messaging Strategies with SwiftBroadcastPro:</SoftTypography>
				<SoftTypography>SwiftBroadcastPro employs strategies to reduce the risk of WhatsApp blocking during mass messaging campaigns:</SoftTypography>

				<ul style={{paddingLeft: '20px'}}>
					<li>
						<SoftTypography variant='h5'>Random Delays:</SoftTypography>
						<SoftTypography>Mass messages are sent with random delays, mimicking the pace of regular messages. However, ultimate control lies with WhatsApp, and users should follow guidelines to mitigate blocking risks.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>Guidelines Compliance:</SoftTypography>
						<SoftTypography>Despite efforts to prevent blocking, WhatsApp account suspensions or bans remain under WhatsApp's purview. Following SwiftBroadcastPro's guidelines is crucial to minimize risks.</SoftTypography>
					</li>
				</ul>

				<SoftTypography variant='h4' mt='10px'>Best Practices Guide:</SoftTypography>
				<ul style={{paddingLeft: '20px'}}>
					<li>
						<SoftTypography variant='h5'>Registration:</SoftTypography>
						<SoftTypography>- Use WhatsApp accounts with mobile numbers and their respective SIM cards.</SoftTypography>
						<SoftTypography>- Avoid using landline or virtual numbers, as they have a higher likelihood of getting banned.</SoftTypography>
						<SoftTypography>- Preferably register new WhatsApp Business accounts from a mobile network.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>Device and App Integrity:</SoftTypography>
						<SoftTypography>- Do not use rooted/jailbroken devices for WhatsApp operations.</SoftTypography>
						<SoftTypography>- Ensure you have an official WhatsApp mobile app downloaded from Google Play/App Store, and keep it updated regularly.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>WhatsApp Account "Warm-up":</SoftTypography>
						<SoftTypography>- Avoid using newly registered WhatsApp accounts for mass messaging.</SoftTypography>
						<SoftTypography>- Warm up newly registered WhatsApp Business accounts with friendly interactions before linking them to SwiftBroadcastPro.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>Safe Sending Practices:</SoftTypography>
						<SoftTypography>- Personalize or vary message content to avoid sending identical mass messages.</SoftTypography>
						<SoftTypography>- Refrain from sending repeated messages to recipients who have blocked your sender number.</SoftTypography>
						<SoftTypography>- Avoid sending specific content types into new chats, such as URLs leading to login pages or adult-related topics.</SoftTypography>
					</li>
					<li>
						<SoftTypography variant='h5'>Attachments:</SoftTypography>
						<SoftTypography>- Don't send attachments as the first message to new chats. Reserve attachments for existing chats.</SoftTypography>
					</li>
				</ul>

				<SoftTypography variant='h4' mt='10px'>In Case of Suspension/Ban:</SoftTypography>
				<ol style={{paddingLeft: '20px'}}>
					<li>
						<SoftTypography>In the event of a SwiftBroadcastPro WhatsApp number ban, it's crucial to thoroughly understand and align with WhatsApp's Acceptable Use of Service.</SoftTypography>
					</li>
					<li>
						<SoftTypography>When reaching out to WhatsApp support to resolve the issue, it's essential to maintain a polite tone and avoid any mention of third-party services or mass messaging practices.</SoftTypography>
					</li>
					<li>
						<SoftTypography>To strengthen your case, provide tangible evidence, such as explicit user consent obtained from individuals you messaged. This could include records demonstrating that these contacts willingly shared their numbers at an offline event or any other occasion, expressly agreeing to be contacted via WhatsApp.</SoftTypography>
					</li>
					<li>
						<SoftTypography>Patiently await a response from WhatsApp support, and upon successful un-banning, reflect on past activities, adhere to prevention guidelines, and consider adjustments to your business processes.</SoftTypography>
					</li>
					<li>
						<SoftTypography>Gradually reintegrate the number, prioritizing initial engagement on the mobile app, followed by WhatsApp web, and eventually, SwiftBroadcastPro.</SoftTypography>
					</li>
					<li>
						<SoftTypography>Exercise caution, monitor activities closely, and emphasize sustained user engagement for a secure and uninterrupted experience.</SoftTypography>
					</li>
				</ol>

				<SoftTypography variant='h4' mt='10px'>Contact SwiftBroadcastPro:</SoftTypography>
				<SoftTypography>If you encounter technical difficulties, contact SwiftBroadcastPro through the 'Contact Us' section or via email at <a href="mailto:contact@swiftbroadcastpro.com">contact@swiftbroadcastpro.com</a> for assistance.</SoftTypography>
      </SoftBox>
			<Footer />
		</DashboardLayout>
  );
}

export default Tips;
