import { useState, useEffect } from "react";
import { toDataURL } from "qrcode";

// formik components
import { Formik, Form } from "formik";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SoftAlert from "components/SoftAlert";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ContactInfo from "./DeviceManagmentSteps/ContactInfo";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { Socket, io } from "socket.io-client";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import swal from "assets/theme/components/swal";
import api from "api";

function getSteps() {
  return ["Contact Info", "Device QR"];
}

function getStepContent(stepIndex, formData, states) {
  switch (stepIndex) {
    case 0:
      return <ContactInfo formData={formData} states={states} />;
    default:
      return null;
  }
}

function AddEdit() {
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [pNumber, setPNumber] = useState("");
  const [emailState, setEmail] = useState("");
  const [info_1, setInfo_1] = useState("");
  const [info_2, setInfo_2] = useState("");
  const [info_3, setInfo_3] = useState("");
  const [info_4, setInfo_4] = useState("");
  const [info_5, setInfo_5] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [oldTags, setOldTags] = useState([]);
  const [disable, setDisable] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    // Soft UI Dashboard React components
    // <SoftAlert>This is an alert!</SoftAlert>;
    // setActiveStep(activeStep - 1);
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const checkInputs = () => {
    return fName.length && emailState.length && pNumber.length && lName.length;
  };

  const emptyStates = () => {
    setFName("");
    setLName("");
    setEmail("");
    setPNumber("");
    setInfo_1("");
    setInfo_2("");
    setInfo_3("");
    setInfo_4("");
    setInfo_5("");
    setSelectedTags([]);
  };

  // useEffect(() => {
  //   console.log("value--------->", selectedTags);
  // }, [selectedTags]);

  const handleSubmit = async () => {
    setDisable(true);
    // showAlert();
    if (checkInputs()) {
      let newTags = [];
      let oldTags = [];
      selectedTags.map((tag) => {
        if (tag !== null && typeof tag === "object") {
          oldTags.push({ tagId: tag.tagId });
        } else {
          newTags.push({ tagName: tag, tagOwner: user.email });
        }
      });

      console.log(oldTags, newTags);
      const data = {
        number: pNumber,
        info1: info_1 ? info_1 : null,
        info2: info_2 ? info_2 : null,
        info3: info_3 ? info_3 : null,
        info3: info_4 ? info_4 : null,
        info3: info_5 ? info_5 : null,
      };

      const [response, alert] = await Promise.all([
        api.post("/contacts/", data),
        Swal.fire({
          icon: "info",
          title: "",
          text: "Submitting data ...",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        }),
      ]);
      if (response.status === 201) {
        console.log("Contact added successfully ==> ", response.data);
        emptyStates();
        setDisable(false);
        await Swal.fire({
          icon: "success",
          title: "Success",
          text: "New Contact added successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          navigate("/contacts/contacts-list");
        }, 500);
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Failed to add new contact, Please try again",
          timer: 2500,
        });
        emptyStates();
        setDisable(false);
      }
    } else {
      await Swal.fire({
        icon: "info",
        title: "Empty fields!",
        text: "Please fill all the required fields",
        showConfirmButton: false,
        timer: 2500,
      });
      setDisable(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          fName,
                          setFName,
                          lName,
                          setLName,
                          pNumber,
                          setPNumber,
                          info_1,
                          setInfo_1,
                          info_2,
                          setInfo_2,
                          info_3,
                          setInfo_3,
                          info_4,
                          setInfo_4,
                          info_5,
                          setInfo_5,
                          emailState,
                          setEmail,
                          selectedTags,
                          setSelectedTags,
                          fetchedTags,
                          setFetchedTags,
                          user,
                          isLoading,
                        })}
                        <SoftBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <SoftButton
                            disabled={disable}
                            variant="gradient"
                            color="dark"
                            onClick={handleSubmit}
                          >
                            Add
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddEdit;
