const form = {
  formId: "new-user-form",
  formField: {
    deviceLabel: {
      name: "deviceLabel",
      label: "device label",
      type: "text",
      placeholder: "eg. Sales Device",
      errorMsg: "Device label is required.",
    },
  },
};

export default form;
