import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAPI from "api";

const initialState = {
  tags: [],
  status: "idle",
  error: null,
};

export const fetchTags = createAsyncThunk("tags/fetchTags", async (email) => {
  // const response = await api.get("tags", {
  //   params: { email },
  // });
  // return response.data;
});

export const addTag = createAsyncThunk("tags/addTag", async (data) => {
  // const { tagName, tagOwner } = data;
  // const response = await api.post("tags/addTag", {
  //   tagName,
  //   tagOwner,
  // });

  // return response.data;
});

export const deleteTag = createAsyncThunk("tags/deleteTag", async (data) => {
  // const response = await api.delete("tags/deleteTag", { data });
  // return response.data;
});

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTags.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tags = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addTag.fulfilled, (state, action) => {
        state.tags = [...state.tags, action.payload];
      })
      .addCase(deleteTag.fulfilled, (state, action) => {});
  },
});

export const getTagsStatus = (state) => state.tags.status;
export const getTagsError = (state) => state.tags.error;

export const selectTags = (state) => state.tags.tags;

export default tagsSlice.reducer;
