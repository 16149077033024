import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link } from "react-router-dom";
import Footer from "examples/Footer";
import SoftButton from "components/SoftButton";
import DataTable from "examples/Tables/DataTable";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import EditContactModal from "./components/EditContactModal";
import { useEffect, useRef, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { CircularProgress, IconButton } from "@mui/material";
import createAPI from "api";
const BASE_URL = process.env.REACT_APP_ENV_URL;

// Action celll for contatcs list
function ActionCell({ setUpdateContacts, contact, contactsTable, api, setContactsTable }) {
  const deleteDevice = async () => {
    const alert = await Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    });
    if (alert.isConfirmed) {
      const [response, processAlert] = await Promise.all([
        api.delete("/contacts/" + contact.id),
        Swal.fire({
          icon: "info",
          title: "",
          text: "Deleting contact ...",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        }),
      ]);
      console.log("response ----------------- ", response);
      if (response.status === 200 || response.status === 204) {
        await Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your contact has been deleted.",
          showConfirmButton: false,
          timer: 1000,
        });
        const test = contactsTable;
        setContactsTable((prev) => {
          let filterValues = [];
          if (prev.hasOwnProperty("rows")) {
            let rows = prev.rows;
            console.log("Rows ------ filter -------- ", rows);
            filterValues = rows.filter((data) => {
              // fix this condition
              return !(data.id === contact.id);
            });
          }
          console.log("filterd values : ===> ", filterValues);
          if (filterValues.length === 0) {
            filterValues = [
              {
                info1: "There are no contacts available.",
              },
            ];
          }
          return { ...prev, rows: filterValues };
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Failed to delete the contact ! Please try again",
          timer: 2600,
          showConfirmButton: false,
        });
      }
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <SoftBox display="flex" alignItems="center">
      {/* <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Preview product" placement="top">
          <Icon>visibility</Icon>
        </Tooltip>
      </SoftTypography> */}
      <SoftBox mx={2}>
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Edit contact" placement="top">
            <Icon onClick={handleOpen}>edit</Icon>
          </Tooltip>
          <EditContactModal setUpdateContacts={setUpdateContacts} contact={contact} open={open} api={api} setOpen={setOpen} />
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title="Delete contact" placement="left">
          <Icon onClick={deleteDevice}>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

function ContactsList() {
	const api = createAPI();
  const windowSize = useRef(window.innerWidth, window.innerHeight);
  const initialState = {
    columns: [
      { Header: "phone number", accessor: "phonenumber", width: "15%" },
      { Header: "info 1", accessor: "info1", width: "15%" },
      { Header: "info 2", accessor: "info2", width: "15%" },
      { Header: "info 3", accessor: "info3", width: "15%" },
      { Header: "info 4", accessor: "info4", width: "15%" },
      { Header: "info 5", accessor: "info5", width: "15%" },
      { Header: "Actions", accessor: "actions", width: "10%" },
    ],
    rows: [
      {
        info1: <CircularProgress color="inherit" />,
      },
    ],
  };
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [contactsTable, setContactsTable] = useState(initialState);
  const [contacts, setContacts] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
	const [updateContacts, setUpdateContacts] = useState(false);

  const fetchContactsData = async (documentId) => {
    const res = await api.get("/contacts/document/" + documentId);
    let arr = [];
    res.data.map((contact) => {
      const obj = {
        id: contact.id,
        // name: contact.fullName,
        phonenumber: contact.number,
        // email: contact.email ? contact.email : "This information is not available",
        info1: contact.info1 ? contact.info1 : "This information is not available",
        info2: contact.info2 ? contact.info2 : "This information is not available",
        info3: contact.info3 ? contact.info3 : "This information is not available",
        info4: contact.info4 ? contact.info4 : "This information is not available",
        info5: contact.info5 ? contact.info5 : "This information is not available",
        actions: (
          <ActionCell
            api={api}
            contact={contact}
            contactsTable={contactsTable}
            setContactsTable={setContactsTable}
						setUpdateContacts={setUpdateContacts}
          />
        ),
      };
      arr.push(obj);
    });
    if (res.data.length === 0) {
      arr = [
        {
          phonenumber: "There are no contacts available",
        },
      ];
    }
    setContactsTable((prev) => {
      return { ...prev, rows: [...arr] };
    });
    setContacts(res.data);
    setIsFetched(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    console.log("Get me contacts from this document: " + searchParams.get("documentId"));
    var documentId = searchParams.get("documentId");
    if (isAuthenticated) fetchContactsData(documentId);
    // console.log("Contacts table -----> ", contactsTable);
  }, [user, updateContacts]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={3}
            >
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Contacts
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Please find below the list of all your contacts, in case you want to see{" "}
                  <br />
                  the list of files you have uploaded, please click on the uploads menu
                  item
                </SoftTypography>
              </SoftBox>
              <Stack spacing={1} direction="row">
                <Link to="/contacts/upload-contacts">
                  <SoftButton variant="gradient" color="info" size="small">
                    Bulk Upload
                  </SoftButton>
                </Link>
                {/* <Link to="/contacts/add-single-contact">
                  <SoftButton variant="gradient" color="info" size="small">
                    Add One
                  </SoftButton>
                </Link> */}
              </Stack>
            </SoftBox>
            <DataTable table={contactsTable} />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ContactsList;
