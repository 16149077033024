import SoftBox from "components/SoftBox";
import React from "react";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import SoftInput from "components/SoftInput";
import Icon from "@mui/material/Icon";
import StyleIcon from "@mui/icons-material/Style";
import SoftTagInput from "components/SoftTagInput";
import { ReactTags } from "react-tag-autocomplete";
import { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { UploadFile } from "@mui/icons-material";
import SoftButton from "components/SoftButton";
import { useAuth0 } from "@auth0/auth0-react";
const BASE_URL = process.env.REACT_APP_ENV_URL;
import { useNavigate } from "react-router";

function MediaUploadZone({
  uploadedFile,
  setUploadedFile,
  email,
  isLoading,
  api,
  selectedTags,
  setSelectedTags,
  setDisble,
  setFile,
}) {
  const navigate = useNavigate();
	const { getAccessTokenSilently } = useAuth0();
	const [token, setToken] = useState(null);
  const [skills, setSkills] = useState([]);
  const [value, setValue] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fetchedTags, setFetchedTags] = useState([]);

	useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getAccessTokenSilently();
      setToken(fetchedToken);
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  const handleDups = (currentVal) => {
    let addVal = true;
    value.some((tag) => {
      console.log(tag.tagName);
      if (tag.tagName === currentVal || tag === currentVal) {
        addVal = false;
        return true;
      }
    });
    if (addVal) {
      setValue([...value, currentVal]);
      setSelectedTags([...value, currentVal]);
    }
  };
  const handleKeyDown = (event) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          handleDups(event.target.value);
        }
        break;
      }
      default:
    }
  };

  // async function fetchAllTags() {
  //   const res = await api.get("/tags", {
  //     params: { email: email ? email : null },
  //   });
  //   const tagsData = res.data;
  //   setFetchedTags((prev) => {
  //     return [...tagsData];
  //   });
  //   let arr = [];
  //   tagsData.map((tag) => {
  //     arr.push(tag);
  //   });
  //   setSkills(arr);
  //   console.log(res.data);
  // }

  // useEffect(() => {
  //   if (!isLoading) {
  //     fetchAllTags();
  //   }
  // }, []);

  return (
    <SoftBox>
      <SoftBox mt={3}>
        {/* <SoftBox mb={2} ml={1} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="2px"
          >
            <StyleIcon fontSize="inherit" /> Tags
          </SoftTypography>
        </SoftBox>
        <Autocomplete
          className="auto-complete"
          multiple
          freeSolo={true}
          forcePopupIcon={true}
          id="tags-outlined"
          options={skills}
          clearOnEscape
          // isOptionEqualToValue={(option, value) => {
          //   console.log(option, value);
          //   return true;
          // }}
          getOptionLabel={(option) => {
            // console.log("inside option label ------- ", option);
            return option.tagName || option;
          }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            setSelectedTags(newValue);
          }}
          // onFocus={() => alert("Loading ... ")}
          // noOptionsText={"No Available Tags"}
          autoHighlight={true}
          filterSelectedOptions
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown;
            return (
              <TextField
                {...params}
                placeholder="Add tags for contacts ..."
                // margin="normal"
                sx={{ marginTop: "-10px" }}
                fullWidth
              />
            );
          }}
        ></Autocomplete> */}
        <SoftDropzone
          options={{
            url: `${BASE_URL}/documents/upload`,
						params: { email: email ? email : null },
						headers: { 'Authorization': `Bearer ${token}`},
            addRemoveLinks: true,
            success: async (file, response) => {
              setIsUploaded(true);
              await Swal.fire({
                icon: "success",
                title: "Success",
                text: "File uploaded successfully",
                showConfirmButton: false,
                timer: 2000,
              });
              setUploadedFile(response);
              setFile(file);
							navigate("/contacts/uploads");
            },
						error: async (file, errorMessage, xhr) => {
							if (errorMessage === 'You can not upload any more files.') {
								await Swal.fire({
									icon: "error",
									title: "Error",
									text: "You can only upload one file. Please remove all existing files from the dropzone to upload a new one.",
								});
							}
							else if (xhr && xhr.response) {
								const errorResponse = JSON.parse(xhr.response);
								const serverErrorMessage = errorResponse.message || "Unknown error occurred";
								await Swal.fire({
									icon: "error",
									title: "Error",
									text: `${serverErrorMessage}`,
								});
							} else {
								await Swal.fire({
									icon: "error",
									title: "Error",
									text: `${errorMessage}`,
								});
							}
						},
            maxFiles: 1,
						maxFilesize: 10 * 1024 * 1024,
						init: function () {
							this.on('addedfile', function (file) {
								if (file.size > 10 * 1024 * 1024) {
									this.removeFile(file);
									Swal.fire({
										icon: "error",
										title: "File Too Large",
										text: "Please upload a file smaller than 10MB",
									});
								}
							});
						},
          }}
        />
      </SoftBox>
    </SoftBox>
  );
}

export default MediaUploadZone;
