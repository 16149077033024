import { Card, CircularProgress, Table } from "@mui/material";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Stack from "@mui/material/Stack";
import StyleIcon from "@mui/icons-material/Style";
import { Autocomplete, TextField } from "@mui/material";

import { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { FILE_TEMPLATE_BASE64 } from "./constant";
import FileSaver from "file-saver";
import SoftTypography from "components/SoftTypography";
import Swal from "sweetalert2";
import createAPI from "api";
import { Link } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import MediaUploadZone from "./components/UploadZone";
import { useSelector } from "react-redux";
import {
  selectTags,
  getTagsStatus,
  fetchTags,
  addTag,
} from "redux/tags/tagsSlice";
import { useDispatch } from "react-redux";
var xlsx = require("xlsx");

const initialState = {
  columns: [
    { Header: "name", accessor: "name", width: "15%" },
    { Header: "phone number", accessor: "phonenumber", width: "15%" },
    { Header: "email", accessor: "email", width: "15%" },
    { Header: "info 1", accessor: "info1", width: "15%" },
    { Header: "info 2", accessor: "info2", width: "15%" },
    { Header: "info 3", accessor: "info3", width: "15%" },
  ],
  rows: [
    {
      info1: <CircularProgress color="inherit" />,
    },
  ],
};

const AddBulk = () => {
	const api = createAPI();
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const tagsRedux = useSelector(selectTags);
  const tagsStatus = useSelector(getTagsStatus);
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [disable, setDisable] = useState(true);
  const [uploadTable, setUploadTable] = useState(initialState);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [value, setValue] = useState([]);
  const [owner, setOwner] = useState(null);
  const [skills, setSkills] = useState([
    { tagName: "empty", tagId: "something" },
  ]);

  function handleTemplateDownload() {
    let dataBlob = FILE_TEMPLATE_BASE64;
    let sliceSize = 1024;
    let byteCharacters = atob(dataBlob);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, offset++) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    let blob = new Blob(byteArrays, { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(new Blob([blob], {}), "template.xlsx");
  }

  const handleSubmit = async () => {
    let tags = [];
    // Check Selected files
    if (!Object.keys(uploadedFile).length) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please upload the file first",
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }

    // Check Selected tags, Separate oldTags from newTags
    selectedTags.map((tag) => {
      if (typeof tag === "string") {
        tagsRedux.map((retTag) => {
          if (tag === retTag.tagName) {
            tags.push({ tagId: retTag.tagId });
          }
        });
      } else {
        tags.push({ tagId: tag.tagId });
      }
    });
    console.log("---------> tags ---------> ", tags);

    // Set Request Data
    const data = {
      email: user ? user.email : null,
      uploadedFile: uploadedFile,
    };

    const [res, processAlert] = await Promise.all([
      api.post("/documents/upload", data),
      Swal.fire({
        icon: "info",
        title: "",
        text: "Submitting the file ...",
        showConfirmButton: false,
        timer: 4500,
        timerProgressBar: true,
      }),
    ]);
    if (res.status === 201) {
      await Swal.fire({
        icon: "success",
        title: "success",
        text: "File submitted successfully",
        timer: 4000,
        showConfirmButton: false,
      });
      setTimeout(() => {
        navigate("/contacts/uploads");
      }, 2000);
    }
  };

  const xslxfileReader = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = xlsx.read(bstr, { type: "binary" });

      // get first sheet :
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const fileData = xlsx.utils.sheet_to_json(workSheet, { header: 1 });
      fileData.splice(0, 8);

      // Fill table of uploaded data :
      setUploadTable((prev) => {
        let newRows = [];
        fileData.some((row) => {
          if (!row.length) return true;
          newRows.push({
            name: row[0],
            phonenumber: row[1],
            email: row[2],
            info1: row[3],
            info2: row[4],
            info3: row[5],
          });
        });
        return { ...prev, rows: newRows };
      });
    };
    reader.readAsBinaryString(file);
  };

  const handleDups = (currentVal) => {
    let addVal = true;
    console.log("values ---> ", value);
    console.log("selected ---> ", selectedTags);
    value.some((tag) => {
      console.log("000000 -----> ", tag);
      if (tag.tagName === currentVal || tag === currentVal) {
        addVal = false;
        return true;
      }
    });
    if (addVal) {
      const data = {
        tagName: currentVal,
        tagOwner: user.email ? user.email : null,
      };
      dispatch(addTag(data));
      setValue([...value, currentVal]);
      setSelectedTags([...value, currentVal]);
    }
  };

  const handleKeyDown = async (event) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        const tagName = event.target.value;
        if (tagName.length > 0) {
          handleDups(tagName);
        }
        break;
      }
      default:
    }
  };

  // async function fillTagsTable() {
  //   const tagsData = tagsRedux;
  //   setFetchedTags((prev) => {
  //     return [...tagsData];
  //   });
  //   let arr = [];
  //   tagsData.map((tag) => {
  //     arr.push(tag);
  //   });
  //   setSkills(arr);
  // }

  useEffect(() => {
    console.log("====> 1");
    if (file) {
      xslxfileReader(file);
    } else {
      setUploadTable((prev) => {
        return { ...prev, rows: [{ info1: "No date to display.", info0: 0 }] };
      });
    }
  }, [file]);

  useEffect(() => {
    console.log("====> 2");
    console.log("seleceted -> ", selectedTags);
  }, [selectedTags]);

  useEffect(() => {
    console.log("====> 3");
    if (!isLoading && tagsStatus === "idle") {
      dispatch(fetchTags(user.email));
      setOwner(user.email);
    }
  }, [user, tagsStatus, dispatch]);

  // useEffect(() => {
  //   console.log("====> 4");
  //   if (tagsStatus === "succeeded") {
  //     fillTagsTable();
  //   }
  // }, [tagsStatus]);

  // useEffect(() => {
  //   console.log("====> 5");
  //   fillTagsTable();
  // }, [tagsRedux]);

  const TagsComponent = () => {
    return (
      <SoftBox p={3} pb={0} pt={0}>
        <SoftBox mb={2} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="2px"
          >
            <StyleIcon fontSize="inherit" /> Tags
          </SoftTypography>
        </SoftBox>
        <Autocomplete
          className="auto-complete"
          multiple
          forcePopupIcon={true}
          id="tags-outlined"
          options={skills}
          clearOnEscape
          getOptionLabel={(option) => {
            return option.tagName || option;
          }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            setSelectedTags(newValue);
          }}
          autoHighlight={true}
          filterSelectedOptions
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown;
            return (
              <TextField
                {...params}
                placeholder="Add Tags (separated by commas or space)"
                sx={{ marginTop: "-10px" }}
                fullWidth
              />
            );
          }}
        ></Autocomplete>
      </SoftBox>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Upload Contacts
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                This page allows you to upload contacts from an excel sheet, <br/>
                please follow the file template by pressing the button on the
                right.
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/contacts/upload-contacts">
                <SoftButton
                  cvariant="contained"
                  color="info"
                  size="small"
                  onClick={handleTemplateDownload}
                >
                  Download Contacts Template
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          {/* <TagsComponent /> */}
          <SoftBox
            p={3}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="space-between"
          >
            <MediaUploadZone
              email={user ? user.email : null}
              api={api}
              isLoading={isLoading}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              setUploadedFile={setUploadedFile}
              uploadedFile={uploadedFile}
              setDisable={setDisable}
              setFile={setFile}
            />
          </SoftBox>
        </Card>
        {/* {uploadTable.rows.length != 0 && uploadTable.rows[0].info0 != 0 ? (
          <>
            <br />
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <SoftBox p={3}>
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    Preview
                  </SoftTypography>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Please double check your data, once satisfied, please press
                    on the submit button.
                  </SoftTypography>
                </SoftBox>
                <DataTable table={uploadTable} />
                <SoftBox mt={2} display="flex" justifyContent="flex-end">
                  <SoftButton
                    sx={{ marginTop: "10px" }}
                    pt={4}
                    mt={6}
                    type="submit"
                    variant="gradient"
                    color="dark"
                    onClick={handleSubmit}
                  >
                    Submit
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Card>
          </>
        ) : (
          <></>
        )} */}
      </SoftBox>
      <Footer mt={5} />
    </DashboardLayout>
  );
};

export default AddBulk;
