import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CircularProgress } from "@mui/material";

const initialState = {
  devices: [],
  status: "idle", // 'loading' 'success' 'failed',
  error: null,
};

export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  async ({ email, api }) => {
		try {
			const response = await api.get("/devices/user/" + email);
			return response.data;
		} catch(err) {
			console.log(err);
			return [];
		}
  }
);

export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  async ({ data, api }) => {
    const response = await api.delete("devices/" + data.deviceId);
    console.log("response data of delete api [REDUX] => ", response.data);
    return response.data;
  }
);

export const updateDevice = createAsyncThunk(
  "devices/updateDevice",
  async (data) => {}
);

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDevices.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.devices = action.payload;
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        const deviceIdToDelete = action.payload.id;
				state.devices = state.devices.filter(device => device.id !== deviceIdToDelete);
      });
  },
});

export const getDevicesStatus = (state) => state.devices.status;
export const getDevicesError = (state) => state.devices.error;

export const selectDevices = (state) => state.devices.devices;
// export const { fetchAllDevices, deleteDevice } = devicesSlice.actions;
export default devicesSlice.reducer;
