import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { useAuth0 } from "@auth0/auth0-react";
import Divider from "@mui/material/Divider";
import {
  CampaignOutlined,
  ContactsOutlined,
  DevicesOutlined,
} from "@mui/icons-material";
import IllustrationLayout from "../../layouts/authentication/components/IllustrationLayout";
import chat from "../../assets/images/illustrations/chat.png";

function Illustration() {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <IllustrationLayout
        illustration={{
          image: chat,
          title: '"Attention is the new currency"',
          description:
            "Stay in touch with your clients with effortless WhatsApp campaigns.",
        }}
      >
        <SoftTypography variant="h1">
					Connect 🌐 Broadcast 📡 Monitor 🖥️
				</SoftTypography>
        <SoftTypography sx={{ pt: "24px" }}>
          Our goal with SwiftBroadcast is simple: to streamline and optimize
          communication, empowering businesses to effortlessly connect with
          their audience, share updates, and address inquiries — all within the
          familiar environment of WhatsApp.
        </SoftTypography>
				<SoftBox mt={5}>
          <SoftButton
            variant="gradient"
            color="info"
            size="large"
            onClick={() => loginWithRedirect()}
            fullWidth
          >
            Get Started
          </SoftButton>
        </SoftBox>
      </IllustrationLayout>
      <Divider />
      <SoftTypography fontWeight="small" sx={{ display: "flex", justifyContent: "center" }}>
        How It Works
      </SoftTypography>
      <SoftTypography variant="h1" sx={{ display: "flex", justifyContent: "center" }}>
        Step-by-step Guide to SwiftBroadcast
      </SoftTypography>
      <SoftTypography pb="10px" sx={{ display: "flex", justifyContent: "center" }}>
        Connect your device, upload your contacts, and start sending campaigns in just a few steps.
      </SoftTypography>
      <SoftBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", mb: "150px" }}>
        <SoftBox sx={{ flex: 1, textAlign: "center", maxWidth: "300px" }}>
          <div style={{ marginBottom: "-40px", fontSize: '100px' }}>
            <DevicesOutlined />
          </div>
          <SoftTypography fontWeight="bold">
            Connect Your Device
          </SoftTypography>
          <SoftTypography textAlign="center" fontWeight="small">
            Connect your device to SwiftBroadcast to start sending campaigns.
          </SoftTypography>
        </SoftBox>
        <SoftBox sx={{ flex: 1, textAlign: "center", maxWidth: "300px" }}>
          <div style={{ marginBottom: "-40px", fontSize: '100px' }}>
            <ContactsOutlined />
          </div>
          <SoftTypography fontWeight="bold">
            Upload Contacts
          </SoftTypography>
          <SoftTypography textAlign="center" fontWeight="small">
            Upload your contact list to SwiftBroadcast to reach your clients.
          </SoftTypography>
        </SoftBox>
        <SoftBox sx={{ flex: 1, textAlign: "center", maxWidth: "300px" }}>
          <div style={{ marginBottom: "-40px", fontSize: '100px' }}>
            <CampaignOutlined />
          </div>
          <SoftTypography fontWeight="bold">
            Send Campaigns
          </SoftTypography>
          <SoftTypography textAlign="center" fontWeight="small">
            Start sending campaigns to your clients and track the progress in real-time.
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default Illustration;
