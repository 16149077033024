import { Card, CircularProgress, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormField from "layouts/pages/account/components/FormField";
import StyleIcon from "@mui/icons-material/Style";
import { Autocomplete, TextField } from "@mui/material";
import Footer from "examples/Footer";
import SoftDropzone from "components/SoftDropzone";
import { useEffect, useState, useRef } from "react";
import SoftButton from "components/SoftButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { useNavigate } from "react-router";
import SoftInput from "components/SoftInput";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from "sweetalert2";
import createAPI from "api";
import { BASE_URL } from "api";
import { Link } from "react-router-dom";

const AddCampaign = () => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
	const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const initialStateDevice = [{ value: "empty", label: "Select device" }];
  const initialStateDocument = [{ value: "empty", label: "Select document" }];
  const [selectDevice, setSelectDevice] = useState(initialStateDevice);
  const [selectDocument, setSelectDocument] = useState(initialStateDocument);
  const [campaignDate, setCampaignDate] = useState("");
  const [campaignTime, setCampaignTime] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [picLocation, setPicLocation] = useState("");
  const [bucket, setBucket] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [enable, setEnable] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [processingPic, setProcessingPic] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [campaignDeviceId, setCampaignDeviceId] = useState("");
  const [deviceCheck, setDeviceCheck] = useState(false);
  const [maxChars, setMaxChars] = useState(105);
  const [isMaxL, setIsMaxL] = useState(false);
  const [value, setValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [skills, setSkills] = useState([
    { tagName: "empty", tagId: "something" },
  ]);

	useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getAccessTokenSilently();
      setToken(fetchedToken);
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  const [media, setMedia] = useState(null);
	const api = createAPI();
  const handleMessageContent = (event) => {
    setMessageContent(event.target.value);
  };

  const emptyStates = () => {
    setCampaignDate("");
    setCampaignTime("");
    setCampaignName("");
    setPicLocation("");
    setMessageContent("");
    setCampaignDeviceId("");
    setValue([]);
    setSelectedTags([]);
  };

  const handleDups = (currentVal) => {
    let addVal = true;
    console.log("values ---> ", value);
    console.log("selected ---> ", selectedTags);
    value.some((tag) => {
      console.log(tag.tagName);
      if (tag.tagName === currentVal || tag === currentVal) {
        addVal = false;
        return true;
      }
    });
    if (addVal) {
      setValue([...value, currentVal]);
      setSelectedTags([...value, currentVal]);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          handleDups(event.target.value);
          // event.target.value = "";
        }
        break;
      }
      default:
    }
  };

  function uploadSuccess(file, response) {
    console.log("---------------------- success ------------------------");
    setProcessingPic(false);

    console.log(file);
    console.log(")))))))))))))))))))))))))))))))");
    console.log(response);
    setPicLocation(response.Location);
    Swal.fire({
      icon: "success",
      title: "",
      text: "Picture uploaded successfully",
      timer: 3000,
      showConfirmButton: false,
    });
    setIsUploaded(true);
    console.log("---------------------- success ------------------------");
  }

  // async function fetchAllTags() {
  //   const res = await api.get("/tags", {
  //     params: { email: user.email },
  //   });
  //   const tagsData = res.data;
  //   setFetchedTags((prev) => {
  //     return [...tagsData];
  //   });
  //   let arr = [];
  //   tagsData.map((tag) => {
  //     arr.push(tag);
  //   });
  //   setSkills(arr);
  //   console.log(res.data);
  // }

  const handleSend = async () => {
		const res = await api.get("/users/" + user.email);
		const userId = res.data.id;
		console.log("User ID: " + userId);
    setEnable(true);
    // console.log("Campaign sent to all users");
    if (
      (documentId != "" || value != "") &&
      deviceId != "" &&
      campaignName != "" &&
      messageContent != ""
    ) {

      const array = [];
      selectedTags.map((tag) => {
        array.push(tag.tagId);
      });

      const data = {
				name: campaignName,
				message: messageContent,
				userId: userId,
				media: picLocation,
				deviceId: deviceId,
				documentId: documentId ? documentId : null
      };

      try {
			const [response, processAlert] = await Promise.all([
        api.post("/campaigns/add-campaign", data),
        Swal.fire({
          icon: "info",
          title: "",
          text: "Creating new campaign ...",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        }),
      ]);

      if (typeof response.data === "string" && response.data !== "") {
        emptyStates();
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: `${response.data}`,
          timer: 4500,
          showConfirmButton: false,
        });
        setEnable(false);
				setTimeout(() => {
          navigate("/campaigns/campaigns-list");
        }, 500);
      } else if (response.status === 201) {
        emptyStates();
        await Swal.fire({
          icon: "success",
          title: "Success",
          text: "New Campaign added successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        setEnable(false);
        setTimeout(() => {
          navigate("/campaigns/campaigns-list");
        }, 500);
      } else {
        emptyStates();
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Failed to add new campaign, Please try again",
          timer: 2500,
          showConfirmButton: false,
        });
        setEnable(false);
				setTimeout(() => {
          navigate("/campaigns/campaigns-list");
        }, 500);
      }} catch(err) {
				emptyStates();
				await Swal.fire({
          icon: "error",
          title: "Oops",
          text: `${err.response.data.message}`,
          timer: 4500,
          showConfirmButton: false,
        });
        setEnable(false);
				setTimeout(() => {
          navigate("/campaigns/campaigns-list");
        }, 500);
			}
    } else {
      await Swal.fire({
        icon: "info",
        title: "Empty fields!",
        text: "Please fill all the required fields",
        showConfirmButton: false,
        timer: 2500,
      });
      setEnable(false);
			setTimeout(() => {
				navigate("/campaigns/campaigns-list");
			}, 500);
    }
  };

  const fetchAllDevices = async () => {
    const res = await api.get("/devices/user/active/" + user.email);
    let devicesArray = [];
    res.data.map((device) => {
      const data = {
        value: device.id,
        label: device.label,
      };
      devicesArray.push(data);
    });
    setSelectDevice((prev) => {
      return [...devicesArray];
    });
  };

  // redux needed !!
  const fetchAllDocuments = async () => {
    const res = await api.get("/documents/user/" + user.email);
    let documents = [];
    res.data.map((document) => {
      const data = {
        value: document.id,
        label: document.filename + " (" + document.contacts + " contacts)",
      };
      documents.push(data);
    });
    setSelectDocument((prev) => {
      return [...documents];
    });
  };

  useEffect(() => {
    if (!isLoading) {
      fetchAllDevices();
      fetchAllDocuments();
      // fetchAllTags();
    }
  }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox m={4}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <SoftTypography variant="h5">Campaign Details</SoftTypography>
          </SoftBox>
          <SoftBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Campaign Name"
                  placeholder="Type in campaign's name"
                  value={campaignName}
                  onChange={(event) => {
                    setCampaignName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Device Name
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  onChange={(value) => {
                    setDeviceId(value.value);
                  }}
                  defaultValue={{ value: "nothing", label: "Select Device" }}
                  options={selectDevice}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Contacts Source
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  onChange={(value) => {
                    setDocumentId(value.value);
                  }}
                  defaultValue={{
                    value: "nothing",
                    label: "Select File",
                  }}
                  options={selectDocument}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Grid item xs={12} sm={12}>
                  <SoftBox>
                    <SoftBox
                      mb={2}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="2px"
                      >
                        <StyleIcon fontSize="inherit" /> Tags
                      </SoftTypography>
                    </SoftBox>
                    <Autocomplete
                      className="auto-complete"
                      multiple
                      // freeSolo={true}
                      forcePopupIcon={true}
                      id="tags-outlined"
                      options={skills}
                      clearOnEscape
                      // isOptionEqualToValue={(option, value) => {
                      //   console.log(option, value);
                      //   return true;
                      // }}
                      getOptionLabel={(option) => {
                        // console.log("inside option label ------- ", option);
                        return option.tagName || option;
                      }}
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                        setSelectedTags(newValue);
                      }}
                      // onFocus={() => alert("Loading ... ")}
                      // noOptionsText={"No Available Tags"}
                      autoHighlight={true}
                      filterSelectedOptions
                      renderInput={(params) => {
                        // params.inputProps.onKeyDown = handleKeyDown;
                        return (
                          <TextField
                            {...params}
                            placeholder="Add tags for contacts ..."
                            // margin="normal"
                            sx={{ marginTop: "-10px" }}
                            fullWidth
                          />
                        );
                      }}
                    ></Autocomplete>
                  </SoftBox>
                </Grid> */}
              </Grid>
              <Grid item xs={12} md={12}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  mb={4}
                  position="relative"
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Message Content
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    // disabled
                    // error={isMaxL}
                    // inputProps={{ maxLength: maxChars }}
                    multiline
                    maxLength={4}
                    rows={6.447}
                    value={messageContent}
                    placeholder="Type in campaign's message ..."
                    onChange={handleMessageContent}
                  />
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    position="absolute"
                    bottom="-22px"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      Swal.fire({
                        icon: "info",
                        title: "Tips",
                        text: `
                        Use the following placeholders to personalise your text: \n\n
                        {number} \n\n
                        {info1} \n\n
                        {info2} \n\n
                        {info3} \n\n
                        {info4} \n\n
                        {info5}`,
                        showConfirmButton: false,
                        timer: 20000,
                      });
                    }}
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{ marginTop: "2px", cursor: "pointer" }}
                    />
                    <SoftTypography
                      fontSize="12.5px"
                      sx={{ marginLeft: "1px", marginTop: "2px" }}
                    >
                      Click here for more info{" "}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>
              {!isUploaded && <Grid item xs={12} md={12}>
                <SoftBox
                  mb={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Upload Pictures
                    </SoftTypography>
                  </SoftBox>
                  <SoftDropzone
                    options={{
                      url: `${BASE_URL}/messages/upload`,
											headers: { 'Authorization': `Bearer ${token}`},
                      addRemoveLinks: true,
                      canceled: (file) => {
                        console.log("--------------------- canceled");
                        Swal.fire({
                          icon: "error",
                          title: "",
                          text: "Failed to upload the picture, Please try again and hold untill the upload is done",
                          timer: 4000,
                          showConfirmButton: false,
                        });
                        setIsUploaded(false);
                      },
											error: async (file, errorMessage, xhr) => {
												if (xhr && xhr.response) {
													const errorResponse = JSON.parse(xhr.response);
													const serverErrorMessage = errorResponse.message || "Unknown error occurred";
													await Swal.fire({
														icon: "error",
														title: "Error",
														text: `${serverErrorMessage}`,
														timer: 4000,
														showConfirmButton: false,
													});
												}
												setIsUploaded(false);
											},
                      success: (file, response) => {
                        console.log(
                          "---------------------- success ------------------------"
                        );
                        setProcessingPic(false);

                        console.log(file);
                        console.log(")))))))))))))))))))))))))))))))");
                        console.log(response);
                        setPicLocation(response);
                        Swal.fire({
                          icon: "success",
                          title: "",
                          text: "Picture uploaded successfully",
                          timer: 3000,
                          showConfirmButton: false,
                        });
                        setIsUploaded(true);
                        console.log(
                          "---------------------- success ------------------------"
                        );
                      },
                      maxFiles: 1,
                      acceptedFiles: "image/*, video/mp4",
											maxFilesize: 10 * 1024 * 1024,
											init: function () {
												this.on('addedfile', function (file) {
													if (file.size > 10 * 1024 * 1024) {
														this.removeFile(file);
														Swal.fire({
															icon: "error",
															title: "File Too Large",
															text: "Please upload a file smaller than 10MB",
														});
													}
												});
											},
                    }}
                  />
                </SoftBox>
              </Grid>}
              <Grid item xs={12} md={12}>
                {isUploaded ? (
                  <div
                    style={{
                      borderRadius: "25px",
                      width: "150px",
                      height: "100px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {picLocation == "" ? (
                      <CircularProgress color="inherit" />
                    ) : picLocation.includes(".mp4") ? (
                      <SoftTypography
                        sx={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "2px",
                          paddingRight: "50px",
                          paddingBottom: "60px",
                        }}
                      >
                        Video Added
                        <CloudUploadIcon fontSize="medium" />
                      </SoftTypography>
                    ) : (
                      <img
                        src={picLocation}
                        alt="uploaded picture"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                          borderRadius: "12px",
                        }}
                        onClick={() => {
                          Swal.fire({
                            text: "Uploaded Picture",
                            imageUrl: `${picLocation}`,
                            imageHeight: 400,
                            imageWidth: 400,

                            imageAlt: "Uploaded image",
                            showConfirmButton: false,
                            timer: 4000,
                          });
                        }}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <SoftBox m={1} mt={2} display="flex" justifyContent="flex-end">
                <SoftButton
                  pt={4}
                  mt={6}
                  disabled={enable}
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    handleSend();
                  }}
                >
                  <Icon>send</Icon>&nbsp; Send
                </SoftButton>
              </SoftBox>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer mt={5} />
    </DashboardLayout>
  );
};

export default AddCampaign;
