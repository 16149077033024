import Modal from "@mui/material/Modal";
import SoftBox from "components/SoftBox";
import { Card, Grid } from "@mui/material";
import FormField from "layouts/pages/users/new-user/components/FormField";
import SoftTypography from "components/SoftTypography";
import { Formik, Form } from "formik";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "#323860",
  transform: "translate(-50%, -50%)",
  width: 470,
  maxHeight: "500px",
  bgcolor: "#ffffff",
  border: "2px solid #fff",
  borderRadius: "5px",
  boxShadow: 24,
  scrollBehavior: "smooth",
};

const EditContactModal = ({ setUpdateContacts, open, contact, api, setOpen }) => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [connectTags, setConnectTags] = useState([]);
  const [createTags, setCreateTags] = useState([]);
  const [pNumber, setPNumber] = useState(contact.number);
  const [info_1, setInfo_1] = useState(contact.info1);
  const [info_2, setInfo_2] = useState(contact.info2);
  const [info_3, setInfo_3] = useState(contact.info3);
  const [info_4, setInfo_4] = useState(contact.info4);
  const [info_5, setInfo_5] = useState(contact.info5);
  const [tagsId, setTagsId] = useState([]);
  const [selectedTags, setSelectedTags] = useState({ tagName: "testing" });
  const { formId, formField } = form;
  const [skills, setSkills] = useState([]);

  const [value, setValue] = useState(() => {
    // return contact.tags.length ? contact.tags : [];
  });
  // console.log("contact tag =========> ", contact);

  const handleDups = (currentVal) => {
    let addVal = true;
    // console.log("values ---> ", value);
    // console.log("selected ---> ", selectedTags);
    console.log("error -------------> ", value);
    value.some((tag) => {
      console.log(tag.tagName);
      if (tag.tagName === currentVal || tag === currentVal) {
        addVal = false;
        return true;
      }
    });
    if (addVal) {
      setValue([...value, currentVal]);
      setSelectedTags([...value, currentVal]);
    }
  };
  const handleKeyDown = (event) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          handleDups(event.target.value);
          // event.target.value = "";
        }
        break;
      }
      default:
    }
  };

  const {phoneNumber, info1, info2, info3, info4, info5 } =
    formField;

  // async function fetchAllTags() {
  //   const res = await api.get("/tags", {
  //     params: { email: contact.owner },
  //   });
  //   const tagsData = res.data;
  //   let arr = [];
  //   // let ids = [];
  //   tagsData.map((tag) => {
  //     arr.push(tag);
  //     // ids.push(tag.tagId);
  //   });
  //   setSkills(arr);
  //   // setTagsId(ids);
  // }

  const checkEmptyFields = (phoneNumber) => {
    if (phoneNumber === "") {
      Swal.fire({
        icon: "info",
        title: "",
        text: "Please Fill All the required Fields [Full Name - Phone Number] ",
        timer: 3500,
        // timerProgressBar: true,
        showConfirmButton: false,
      });
      return false;
    }
    return true;
  };

  const checkForChanges = (
    { phoneNumber, info1, info2, info3, info4, info5, oldTags, newTags },
    cTags
  ) => {
    let checkTags = true;
    const objValue = value;
    const valueLength = objValue.length;
    let objTags = [];
    if (valueLength) {
      // check the length of selected values and contacts.tags {In case of deleting a tag from the selected field}
      if (valueLength !== cTags.length) {
        checkTags = false;
      }

      // Map through all selected tags check for ones that are objects and check their ids,
      // then for non-object ones to specify the new ones and the already creaed ones
      objValue.map((tag) => {
        if (typeof tag === "object") {
          if (!cTags.includes(tag.tagId)) {
            checkTags = false;
          }
          oldTags.push({ tagId: tag.tagId });
        } else {
          checkTags = false;
          newTags.push({ tagName: tag });
        }
      });
    }

    if (
      phoneNumber === contact.phoneNumber &&
      info1 === contact.info1 &&
      info2 === contact.info2 &&
      info3 === contact.info3 &&
      info4 === contact.info4 &&
      info5 === contact.info5
    ) {
      Swal.fire({
        icon: "info",
        title: "",
        text: "Please Make Your Changes First !",
        timer: 3500,
        showConfirmButton: false,
      });
      return false;
    }
    return true;
  };

  const editContactData = async () => {};

  const handleSubmit = async () => {
    if (!checkEmptyFields(pNumber)) return;
    const data = {
      number: pNumber,
      info1: info_1,
      info2: info_2,
      info3: info_3,
      info4: info_4,
      info5: info_5,
    };
    // let cTags = [];
    // contact.tags.map((tag) => {
    //   cTags.push(tag.tagId);
    // });
    // if (!checkForChanges(data, cTags)) return;
    console.log("Submitting this data ------------------------");
    console.log(data);
    console.log("---------------------------------------------");
    const [response, processAlert] = await Promise.all([
      api.patch(`/contacts/${contact.id}`, data),
      Swal.fire({
        icon: "info",
        title: "",
        text: "Updating contact ...",
        timer: 3500,
        timerProgressBar: true,
        showConfirmButton: false,
      }),
    ]);
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "",
        text: "Contact updated successfully",
        timer: 1500,
        showConfirmButton: false,
      });
			setPNumber(response.data.number);
			setInfo_1(response.data.info1);
			setInfo_2(response.data.info2);
			setInfo_3(response.data.info3);
			setInfo_4(response.data.info4);
			setInfo_5(response.data.info5);
			setValue(() => {});
			setOpen(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Contact Did not updated, Please try again",
        timer: 2500,
        showConfirmButton: false,
      });
			handleClose();
    }
    console.log("response =============> \n", response);
  };

  const handleClose = () => {
    setPNumber(contact.phoneNumber);
    setInfo_1(contact.info1);
    setInfo_2(contact.info2);
    setInfo_3(contact.info3);
    setInfo_4(contact.info4);
    setInfo_5(contact.info5);
    setValue(() => {
      // return contact.tags.length ? contact.tags : [];
    });
    setOpen(false);
  };

  useEffect(() => {
		if (!open) {
			setUpdateContacts(false);
		} else {
			setUpdateContacts(true);
		}
  }, [open]);

  return (
    // <div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SoftBox sx={style}>
        <SoftBox
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SoftTypography p={2} sx={{ fontSize: "25px" }}>
            Edit Contact
          </SoftTypography>
        </SoftBox>
        <Grid item xs={12} lg={6}>
          <Formik initialValues={initialValues}>
            <Form id={formId} autoComplete="off">
              <Card
                sx={{
                  overflow: "auto",
                  width: "100%",
                  height: "auto",
                  maxHeight: "420px",
                }}
              >
                <SoftBox sx={{ padding: "0px 10px 0px 10px" }}>
                  {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type={firstName.type}
                        label={"Full Name"}
                        name={firstName.name}
                        value={fullName}
                        placeholder={"eg John Snow"}
                      />
                    </Grid> */}
                  {/* </Grid> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={phoneNumber.type}
                        label={phoneNumber.label}
                        name={phoneNumber.name}
                        onChange={(e) => setPNumber(e.target.value)}
                        value={pNumber}
                        placeholder={phoneNumber.placeholder}
                        // error={errors.phoneNumber && touched.phoneNumber}
                        // success={pNumber.length > 0 && !errors.phoneNumber}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailState}
                        placeholder={email.placeholder}
                        // error={errors.email && touched.email}
                        // success={emailState.length > 0 && !errors.email}
                      />
                    </Grid> */}
                  </Grid>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={12}>
                      <SoftBox mb={1.5}>
                        <SoftBox
                          mb={2}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap="2px"
                          >
                            <StyleIcon fontSize="inherit" /> Tags
                          </SoftTypography>
                        </SoftBox>
                        <Autocomplete
                          className="auto-complete"
                          multiple
                          freeSolo={true}
                          forcePopupIcon={true}
                          id="tags-outlined"
                          options={skills}
                          clearOnEscape
                          getOptionLabel={(option) => {
                            return option.tagName || option;
                          }}
                          value={value}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                            setSelectedTags(newValue);
                          }}
                          autoHighlight={true}
                          filterSelectedOptions
                          renderInput={(params) => {
                            params.inputProps.onKeyDown = handleKeyDown;
                            return (
                              <TextField
                                {...params}
                                placeholder="Add tags for contacts ..."
                                sx={{ marginTop: "-10px" }}
                                fullWidth
                              />
                            );
                          }}
                        ></Autocomplete>
                      </SoftBox>
                    </Grid> */}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        type={info1.type}
                        label={info1.label}
                        name={info1.name}
                        onChange={(e) => setInfo_1(e.target.value)}
                        value={info_1}
                        placeholder={info1.placeholder}
                        // error={errors.info1 && touched.info1}
                        // success={info_1.length > 0 && !errors.info1}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        type={info2.type}
                        label={info2.label}
                        name={info2.name}
                        onChange={(e) => setInfo_2(e.target.value)}
                        value={info_2}
                        placeholder={info2.placeholder}
                        // error={errors.info2 && touched.info2}
                        // success={info_1.length > 0 && !errors.info1}

                        // success={emailV.length > 0 && !errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        type={info3.type}
                        label={info3.label}
                        name={info3.name}
                        onChange={(e) => setInfo_3(e.target.value)}
                        value={info_3}
                        placeholder={info3.placeholder}
                        // error={errors.info3 && touched.info3}
                        // success={emailV.length > 0 && !errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        type={info4.type}
                        label={info4.label}
                        name={info4.name}
                        onChange={(e) => setInfo_4(e.target.value)}
                        value={info_4}
                        placeholder={info4.placeholder}
                        // error={errors.info4 && touched.info4}
                        // success={emailV.length > 0 && !errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        type={info5.type}
                        label={info5.label}
                        name={info5.name}
                        onChange={(e) => setInfo_5(e.target.value)}
                        value={info_5}
                        placeholder={info5.placeholder}
                        // error={errors.info5 && touched.info5}
                        // success={emailV.length > 0 && !errors.email}
                      />
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox
                  m={2}
                  // width="100%"
                  display="flex"
                  justifyContent="center"
                  gap={2}
                >
                  <SoftButton
                    //   disabled={false}
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                  <SoftButton
                    //   disabled={false}
                    variant="outlined"
                    color="dark"
                    onClick={handleSubmit}
                  >
                    Confirm
                  </SoftButton>
                </SoftBox>
              </Card>
            </Form>
          </Formik>
        </Grid>
      </SoftBox>
    </Modal>
  );
};

export default EditContactModal;
