const form = {
  formId: "new-contact-form",
  formField: {
    phoneNumber: {
      name: "phoneNumber",
      label: "phone number",
      type: "text",
      placeholder: "eg. 212766954506",
      errorMsg: "Phone number is required.",
    },
    info1: {
      name: "info1",
      label: "info1",
      type: "text",
      placeholder: "Insert additional inforamtion ",
      errorMsg: "",
    },
    info2: {
      name: "info2",
      label: "info2",
      type: "text",
      placeholder: "Insert additional inforamtion ",
      errorMsg: "",
    },
    info3: {
      name: "info3",
      label: "info3",
      type: "text",
      placeholder: "Insert additional inforamtion ",
      errorMsg: "",
    },
    info4: {
      name: "info4",
      label: "info4",
      type: "text",
      placeholder: "Insert additional inforamtion ",
      errorMsg: "",
    },
    info5: {
      name: "info5",
      label: "info5",
      type: "text",
      placeholder: "Insert additional inforamtion ",
      errorMsg: "",
    },
  },
};

export default form;
