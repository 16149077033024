import { useState, useEffect } from "react";
import { toDataURL } from "qrcode";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

import DeviceInfo from "./DeviceManagmentSteps/DeviceInfo";
import DeviceQR from "./DeviceManagmentSteps/DeviceQR";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate } from "react-router";
import socket from './socket'

function getSteps() {
  return ["Device Info", "Device QR"];
}

function getStepContent(stepIndex, formData, setIsDone) {
  switch (stepIndex) {
    case 0:
      return <DeviceInfo formData={formData} />;
    case 1:
      return <DeviceQR socket={socket} label={formData.values.deviceLabel} setIsDone={setIsDone} />;
    default:
      return null;
  }
}

function AddEdit() {
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [isDone, setIsDone] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleCancel = () => {
		navigate("/devices/devices-list");
	}

  const submitForm = async (values, actions) => {
    await sleep(1000);

    actions.setSubmitting(false);
    actions.resetForm();

    // setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      console.log("+++++++++++++++++++++++++++++++send");
      navigate("/devices/devices-list");
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      console.log(values);
      const deviceName = values.deviceLabel;
      const deviceOwner = user.email;

      // socket.emit("addNewDevice", { deviceName, deviceOwner });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={2}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                          },
                          setIsDone
                        )}
                        <SoftBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
													<SoftButton
														variant="gradient"
														color="light"
														onClick={handleCancel}
													>
														cancel
													</SoftButton>
                          <SoftButton
                            disabled={isDone}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "done" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddEdit;
