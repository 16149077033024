import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={476}
    height={124}
    viewBox="0 0 476 124"
    backgroundColor="#344767"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="4" rx="10" ry="10" width="240" height="111" />
    <rect x="0" y="138" rx="3" ry="3" width="72" height="34" />
    <rect x="0" y="196" rx="3" ry="3" width="380" height="6" />
  </ContentLoader>
);

export default MyLoader;
