import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import SoftAlert from "components/SoftAlert";

function Reviews({ campaigns }) {

	const lastFiveCampaigns = campaigns.slice(0, 5);
  const rows = [];
  lastFiveCampaigns.forEach((campaign) => {
    const percentage =
      campaign.targetMessagesCount == 0
        ? "---"
        : Math.ceil((campaign.sentMessages / campaign.targetMessagesCount) * 100);
			const formattedDate = new Date(campaign.created).toLocaleString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
				month: '2-digit',
				day: '2-digit',
				year: 'numeric',
			});
			rows.push(
      <SoftBox component="li" w="100%" py={1} mb={1.5}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {campaign.campaignName} - {formattedDate}
          </SoftTypography>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {percentage}%
          </SoftTypography>
        </SoftBox>
        <SoftProgress variant="gradient" value={percentage} />
      </SoftBox>
    );
  });

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Campaigns
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={2} px={2}>
        {campaigns == null ||
          (campaigns.length == 0 && (
            <SoftAlert color="primary" dismissible>
              <SoftTypography variant="body2" color="white">
                You currently have no campaigns
              </SoftTypography>
            </SoftAlert>
          ))}

        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {rows}
        </SoftBox>
      </SoftBox>
      <SoftBox
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <SoftBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {/* Your campaign messages are currently seperated  */}
          </SoftTypography>
        </SoftBox>

        <SoftBox
          width={{ xs: "100%", sm: "40%" }}
          textAlign="right"
          mt={{ xs: 2, sm: "auto" }}
        >
          {campaigns != null && campaigns.length != 0 && (
            <Link to="/campaigns/campaigns-list">
              <SoftButton variant="gradient" color="dark">
                view all campaigns
              </SoftButton>
            </Link>
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Reviews;
