import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import Moment from "moment";

import { useEffect, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { CircularProgress, Grid } from "@mui/material";
import createAPI from "../../api";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import SoftButton from "components/SoftButton";
import ActionCell from "./components/ActionCell";

function UploadsList() {
	const api = createAPI();
  const initialState = {
    columns: [
      { Header: "File Name", accessor: "filename", width: "15%" },
      { Header: "Created Time", accessor: "created", width: "15%" },
      { Header: "Number of Contacts", accessor: "contacts", width: "15%" },
      { Header: "Actions", accessor: "actions", width: "10%" },
    ],
    rows: [
      {
        created: <CircularProgress color="inherit" />,
      },
    ],
  };
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [documentsTable, setDocumentsTable] = useState(initialState);
  const [documents, setDocuments] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  const fetchDocumentsData = async () => {
    const res = await api.get(`/documents/user/${user.email}`);
    let arr = [];
    res.data.map((document) => {
      const obj = {
        id: document.id,
        filename: document.filename,
        created: Moment(document.created).format("DD-MM-YYYY HH:mm:ss"),
        contacts: document.contacts,
        actions: (
          <ActionCell
            api={api}
            document={document}
            documentsTable={documentsTable}
            setDocumentsTable={setDocumentsTable}
          />
        ),
      };
      arr.push(obj);
    });
    if (res.data.length === 0) {
      arr = [
        {
          created: "There are no documents available",
        },
      ];
    }
    setDocumentsTable((prev) => {
      return { ...prev, rows: [...arr] };
    });
    setDocuments(res.data);
    setIsFetched(true);
  };

  useEffect(() => {
    if (isAuthenticated) fetchDocumentsData();
  }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={3}
            >
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Uploaded Files
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Please find below the list of all bulk uploads, by pressing on
                  the expand <br />
                  button you will be able to see all contacts within
                </SoftTypography>
              </SoftBox>
              <Stack spacing={1} direction="row">
                <Link to="/contacts/upload-contacts">
                  <SoftButton variant="gradient" color="info" size="small">
                    Bulk Upload
                  </SoftButton>
                </Link>
                {/* <Link to="/contacts/add-single-contact">
                  <SoftButton variant="gradient" color="info" size="small">
                    Add One Contact
                  </SoftButton>
                </Link> */}
              </Stack>
            </SoftBox>
            <DataTable table={documentsTable} />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UploadsList;
