import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import { CircularProgress, Grid } from "@mui/material";

// Action celll for contatcs list
export default function ActionCell({
  document,
  documentsTable,
  api,
  setDocumentsTable,
}) {
  const navigate = useNavigate();

  const deleteDocument = async () => {
    const alert = await Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    });
    if (alert.isConfirmed) {
      // api.patch()
      const [response, processAlert] = await Promise.all([
				api.delete(`/documents/${document.id}`),
        Swal.fire({
          icon: "info",
          title: "",
          text: "Deleting document ...",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        }),
      ]);
      if (response.status === 200 || response.status === 204) {
        await Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your document has been deleted.",
          showConfirmButton: false,
          timer: 1000,
        });
        const test = documentsTable;
        setDocumentsTable((prev) => {
          let filterValues = [];
          if (prev.hasOwnProperty("rows")) {
            let rows = prev.rows;
            filterValues = rows.filter((data) => {
              console.log(data);
              console.log(data.id);
              console.log(document.id);
              // fix this condition
              return !(data.id === document.id);
            });
          }
          if (filterValues.length === 0) {
            filterValues = [
              {
                created: "There are no documents available.",
              },
            ];
          }
          return { ...prev, rows: filterValues };
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Failed to delete the document ! Please try again",
          timer: 2600,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <SoftTypography
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Expand Document" placement="left">
            <Icon
              onClick={() => {
                navigate("/contacts/contacts-list?documentId=" + document.id);
              }}
            >
              open_with
            </Icon>
          </Tooltip>
        </SoftTypography>
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox display="flex" alignItems="center">
          <SoftTypography
            color="secondary"
            sx={{ cursor: "pointer", lineHeight: 0 }}
          >
            <Tooltip title="Delete document" placement="right">
              <Icon onClick={deleteDocument}>delete</Icon>
            </Tooltip>
          </SoftTypography>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
