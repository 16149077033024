/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Dashboard from "pages/dashboard/index";
import Login from "pages/authentication/Login";
// import Dashboard from "layouts/dashboards/crm";
import PricingPage from "layouts/pages/pricing-page";
import AddEdit from "pages/devices/AddEdit";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Overview from "layouts/ecommerce/overview";
import Referral from "layouts/ecommerce/referral";

// Soft UI Dashboard PRO React icons
import Office from "examples/Icons/Office";
import Device from "examples/Icons/Device";
import Campaign from "examples/Icons/Campaign";
import Contacts from "examples/Icons/Contacts";
import ProductsList from "layouts/ecommerce/products/products-list";
import DevicesList from "pages/devices/List";
import AddBulk from "pages/contacts/AddBulk";
import ContactsList from "pages/contacts/ContactsList";
import AddCampaign from "pages/campaigns/AddCampaign";
import CampaignsList from "pages/campaigns/CampaignsList";
import AddContact from "pages/contacts/AddContact";
import UploadsList from "pages/contacts/UploadsList";
import Tips from "pages/tips/tips";
import List from "pages/messages/List";
import {
  CampaignOutlined,
  ContactsOutlined,
  DashboardCustomizeOutlined,
  DevicesOutlined,
  Message,
  WhatsappOutlined,
	VerifiedUserOutlined,
	TipsAndUpdatesOutlined
} from "@mui/icons-material";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardCustomizeOutlined size="12px" />,
    noCollapse: true,
    route: "/dashboard",
    component: <Dashboard />,
    display: true,
  },
  {
    type: "dont_show",
    name: "Login",
    key: "login",
    noCollapse: true,
    route: "/landing",
    component: <Login />,
    display: true,
  },
  {
    type: "collapse",
    name: "Devices",
    key: "devices",
    icon: <DevicesOutlined size="12px" />,
    display: true,
    collapse: [
      {
        name: "Devices List",
        key: "devices-list",
        route: "/devices/devices-list",
        component: <DevicesList />,
        display: true,
      },
      {
        name: "Add Device",
        key: "add-device",
        route: "/devices/add-device",
        component: <AddEdit />,
        display: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Contacts",
    key: "contacts",
    icon: <ContactsOutlined size="12px" />,
    display: true,
    collapse: [
      {
        name: "Contacts List",
        key: "contacts-list",
        route: "/contacts/contacts-list",
        component: <ContactsList />,
        display: false,
      },
      {
        name: "Contacts Lists",
        key: "uploads-list",
        route: "/contacts/uploads",
        component: <UploadsList />,
        display: true,
      },
      {
        name: "Upload Contacts",
        key: "upload-contacts",
        route: "/contacts/upload-contacts",
        component: <AddBulk />,
        display: true,
      },
      {
        name: "Add Single Contact",
        key: "add-single-contact",
        route: "/contacts/add-single-contact",
        component: <AddContact />,
        display: false,
      },
    ],
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <CampaignOutlined size="12px" />,
    display: true,
    collapse: [
			{
				name: "Campaigns List",
				key: "campaigns-list",
				route: "/campaigns/campaigns-list",
				display: true,
				component: <CampaignsList />,
			},
      {
        name: "Add Campaign",
        key: "add-campaign",
        route: "/campaigns/add-campaign",
        display: true,
        component: <AddCampaign />,
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Messages",
  //   key: "messages",
  //   icon: <Message size="12px" />,
  //   display: true,
  //   collapse: [
  //     {
  //       name: "List",
  //       key: "messagesList",
  //       route: "/messages/list",
  //       component: <List />,
  //       display: true,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Become Premium",
    key: "premium",
    icon: <VerifiedUserOutlined size="12px" />,
    noCollapse: true,
    href: process.env.REACT_APP_PAYMENT_LINK,
    display: true,
  },
  {
    type: "collapse",
    name: "Messaging Tips",
    key: "tips",
    icon: <TipsAndUpdatesOutlined size="12px" />,
    noCollapse: true,
    display: true,
		route: "/tips",
		component: <Tips />,
  },
  {
    type: "collapse",
    name: "Contact Us",
    key: "whatsapp",
    icon: <WhatsappOutlined size="12px" />,
    noCollapse: true,
    href: "https://wa.me/966510901312",
    display: true,
  },
];

export default routes;
