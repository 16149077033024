// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { deleteDevice } from "redux/devices/devicesSlice";
import { getDevicesStatus } from "redux/devices/devicesSlice";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import Swal from "sweetalert2";
import EditDevice from "../../EditDevice";

function ActionCell({ device, tableState, api, setTableState, setUpdated }) {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
  const devicesStatus = useSelector(getDevicesStatus);
  const deleteDeviceHandler = () => {
    dispatch(deleteDevice({ data: { deviceId: device.id }, api }));
  };
  const editDevice = async () => {
    await Swal.fire({
      icon: "info",
      title: "",
      text: "We are still working on it...",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const deleteTheDevice = async () => {
    const alert = await Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    });
    if (alert.isConfirmed) {
      const [response, processAlert] = await Promise.all([
				deleteDeviceHandler(),
        Swal.fire({
          icon: "info",
          title: "",
          text: "Deleting device ...",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        }),
      ]);
      if (devicesStatus === 'succeeded') {
        await Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your device has been deleted.",
          showConfirmButton: false,
          timer: 1000,
        });
        setTableState((prev) => {
          let filterValues = [];
          if (prev.hasOwnProperty("rows")) {
            let rows = prev.rows;
            filterValues = rows.filter((data) => {
              return data.deviceId !== device.id;
            });
          }
          console.log("filterd values : ===> ", filterValues);
          if (filterValues.length === 0) {
            filterValues = [
              {
                label: "There are no devices available.",
              },
            ];
          }
          return { ...prev, rows: filterValues };
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Failed to delete the device ! Please try again",
          timer: 2600,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2}>
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Edit product" placement="top">
            <Icon onClick={handleOpen}>edit</Icon>
          </Tooltip>
					<EditDevice  open={open} setOpen={setOpen} device={device} api={api} setUpdated={setUpdated} />
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title="Delete product" placement="left">
          <Icon onClick={deleteTheDevice}>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

export default ActionCell;
