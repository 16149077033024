import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function MsgActionCell({ setOkay, okay, data, setSelectedContact }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0, marginRight: "12px" }}
      >
        <Tooltip title="Preview Message" placement="top">
          <Icon
            onClick={() => {
              setOkay((prev) => !prev);
              setSelectedContact(data);
            }}
          >
            visibility
          </Icon>
          {/* <VisibilityOffIcon
            onClick={() => {
              setOkay((prev) => !prev);
            }}
          /> */}
        </Tooltip>
      </SoftTypography>
      {/* <SoftBox mx={2}>
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Edit product" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox> */}
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title="Delete Message" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

export default MsgActionCell;
