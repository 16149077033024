import checkout from "./form";

const {
  formField: { phoneNumber, info1, info2, info3, info4, info5 },
} = checkout;

const initialValues = {
  [phoneNumber.name]: "",
  [info1.name]: "",
  [info2.name]: "",
  [info3.name]: "",
  [info4.name]: "",
  [info5.name]: "",
};

export default initialValues;
