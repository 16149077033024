const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  contacts: [],
  status: "idle",
  error: null,
};

export const fetchContacts = createAsyncThunk(
  "contacts/fetchContacts",
  async ({data, api}) => {
    const response = await api.get("/contacts/" + data.documentId);
    return response.data;
  }
);

export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async ({data, api}) => {
    const response = await api.delete("contacts/" + data.contactId);
    return response.data;
  }
);

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchContacts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        console.log("contactDelete Action => ", action.payload);
      });
  },
});

export const getContactsStatus = (state) => state.contacts.status;
export const getContactsError = (state) => state.contacts.error;
export const selectContacts = (state) => state.contacts.contacts;

export default contactsSlice.reducer;
