// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function UserInfo({ formData }) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { formField, values, errors, touched } = formData;
  const { deviceLabel } = formField;
  const { deviceLabel: deviceLabelV } = values;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Please provide details about this device
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          We would like you to know that once the device has been properly
          added, you will have all the messages sent from this
          device on WhatsApp stored in your account.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={deviceLabel.type}
              label={deviceLabel.label}
              name={deviceLabel.name}
              value={deviceLabelV}
              placeholder={deviceLabel.placeholder}
              error={errors.deviceLabel && touched.deviceLabel}
              success={deviceLabelV.length > 0 && !errors.deviceLabel}
            />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
