import { configureStore } from "@reduxjs/toolkit";
import devicesSlice from "./devices/devicesSlice";
import contactsSlice from "./contacts/contactsSlice";
import messagesSlice from "./messages/messagesSlice";
import tagsSlice from "./tags/tagsSlice";

export default configureStore({
  reducer: {
    devices: devicesSlice,
    contacts: contactsSlice,
    messages: messagesSlice,
    tags: tagsSlice,
  },
});
