// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import ActionCell from "./components/ActionCell/index";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import createAPI from "../../api";
import { useSelector, useDispatch } from "react-redux";
import {
  getDevicesError,
  getDevicesStatus,
  selectDevices,
  fetchDevices,
} from "redux/devices/devicesSlice";
import SoftBadge from "components/SoftBadge";
import { loadStripe } from '@stripe/stripe-js';

const disabled = (
  <SoftBadge
    variant="contained"
    color="error"
    size="xs"
    badgeContent="DISABLED"
    container
  />
);
const active = (
  <SoftBadge
    variant="contained"
    color="success"
    size="xs"
    badgeContent="ACTIVE"
    container
  />
);

function DevicesList() {
	const api = createAPI();
	const [updated, setUpdated] = useState(false);
	const [isPremiumUser, setIsPremiumUser] = useState(false);
  const devicesRedux = useSelector(selectDevices);
  const devicesStatus = useSelector(getDevicesStatus);
  const devicesError = useSelector(getDevicesError);
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const initialState = {
    columns: [
      {
        Header: "Device",
        accessor: "label",
        width: "20%",
      },
      {
        Header: "ID",
        accessor: "deviceId",
        width: "30%",
      },
      {
        Header: "status",
        accessor: "status",
      },
      { Header: "action", accessor: "action" },
    ],

    rows: [
      {
        ownerName: <CircularProgress color="inherit" />,
      },
    ],
  };
  const [tableState, setTableState] = useState(initialState);

  useEffect(() => {
    if (!isLoading && devicesStatus === 'idle') {
			dispatch(fetchDevices({ email: user.email, api }));
    }
  }, [user, devicesStatus, dispatch]);

	useEffect(() => {
		if (!isLoading) {
			dispatch(fetchDevices({ email: user.email, api }));
		}
  }, [updated]);

  const dataTable = () => {
    let data = devicesRedux;
    let arr = [];
    data.map((data) => {
      const obj = {
        deviceId: data.id,
        label: data.label,
        status: data.status == 1 ? active : disabled,
        action: (
          <ActionCell
            device={data}
            api={api}
            setTableState={setTableState}
            tableState={tableState}
						setUpdated={setUpdated}
          />
        ),
      };
      arr.push(obj);
    });
    if (data.length === 0) {
      arr = [
        {
          label: "There are no devices available",
        },
      ];
    }
    setTableState((prev) => {
      return { ...prev, rows: [...arr] };
    });
  };

  useEffect(() => {
    console.log("Re-render...");
    if (devicesStatus === "succeeded") dataTable();
    console.log("-----> ", tableState);
  }, [devicesStatus]);
 
	useEffect(() => {
		const getUserStatus = async () => {
			const res = await api.get(`/users/${user.email}`);
			if (res.data.paid) {
				setIsPremiumUser(true);
			}
		}
		if (isAuthenticated && user && !isLoading) {
			getUserStatus();
		}
	}, [user])
	
	const handleUpgrade = async () => {
		const stripe = await loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);	
		if (stripe) {
			const email = encodeURIComponent(user.email);
			window.location.href = `${process.env.REACT_APP_PAYMENT_LINK}?prefilled_email=${email}`;
		}
		toast.dismiss();
    console.log('Upgrade to Premium');
  };

  const notifyPremiumRequired = () => {
		console.log('Premium Required');
    toast.warn(
      <div>
        Oops! It looks like you're on our Freemium plan. Only our Premium users have the ability to add more than one device.
				<div style={{ display: 'flex', justifyContent: 'center' }}>
				<SoftButton
					variant="gradient"
					color="info"
					size="small"
					onClick={handleUpgrade}>
					Upgrade to Premium
				</SoftButton>
				</div>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
				icon: false,
      }
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Devices
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Please find below the list of all your devices
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
						{
						(devicesRedux.length >= 1 && !isPremiumUser) ? (
							<div>
								<SoftButton
										variant="gradient"
										color="info"
										size="small"
										onClick={notifyPremiumRequired}
								>
									+ new device
								</SoftButton>
								<ToastContainer />
							</div>) : (
							<Link to="/devices/add-device">
                <SoftButton variant="gradient" color="info" size="small">
                  + new device
                </SoftButton>
              </Link>
						)}
            </Stack>
          </SoftBox>
          <DataTable
            table={tableState}
            entriesPerPage={{
              defaultValue: 7,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DevicesList;
