// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import StyleIcon from "@mui/icons-material/Style";
import axios from "axios";

import { Autocomplete, TextField } from "@mui/material";
import api from "api";
// const BASE_URL = process.env.REACT_APP_ENV_URL;
// const api = axios.create({
//   baseURL: `${BASE_URL}`,
// });

function UserInfo({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    fName,
    setFName,
    lName,
    setLName,
    emailState,
    setEmail,
    pNumber,
    setPNumber,
    info_1,
    setInfo_1,
    info_2,
    setInfo_2,
    info_3,
    setInfo_3,
    selectedTags,
    setSelectedTags,
    fetchedTags,
    setFetchedTags,
    user,
    isLoading,
  } = formData;
  const [skills, setSkills] = useState([
    { tagName: "empty", tagId: "something" },
  ]);
  const [value, setValue] = useState([]);

  const handleDups = (currentVal) => {
    let addVal = true;
    console.log("values ---> ", value);
    console.log("selected ---> ", selectedTags);
    value.some((tag) => {
      console.log(tag.tagName);
      if (tag.tagName === currentVal || tag === currentVal) {
        addVal = false;
        return true;
      }
    });
    if (addVal) {
      setValue([...value, currentVal]);
      setSelectedTags([...value, currentVal]);
    }
  };
  const handleKeyDown = (event) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          handleDups(event.target.value);
          // event.target.value = "";
        }
        break;
      }
      default:
    }
  };

  // async function fetchAllTags() {
  //   const res = await api.get("/tags", {
  //     params: { email: user.email },
  //   });
  //   const tagsData = res.data;
  //   setFetchedTags((prev) => {
  //     return [...tagsData];
  //   });
  //   let arr = [];
  //   tagsData.map((tag) => {
  //     arr.push(tag);
  //   });
  //   setSkills(arr);
  //   console.log(res.data);
  // }

  // useEffect(() => {
  //   if (!isLoading) fetchAllTags();
  // }, []);

  useEffect(() => {
    console.log(fetchedTags);
  }, [fetchedTags]);

  const { firstName, phoneNumber, lastName, email, info1, info2, info3 } =
    formField;

  // useEffect(() => {
  // }, [info_3]);

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h6" fontWeight="bold">
          Please provide details about this contact
        </SoftTypography>
        {/* <SoftTypography variant="button" fontWeight="regular" color="text">
          We would like you to know that once the device has been properly added, you will have all
          the messages sent or received from this device on WhatsApp will be stored into your
          account.
        </SoftTypography> */}
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              placeholder={firstName.placeholder}
              // error={errors.firstName && touched.firstName}
              // success={fName.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lName}
              onChange={(e) => setLName(e.target.value)}
              placeholder={lastName.placeholder}
              // error={errors.lastName && touched.lastName}
              // success={lName.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phoneNumber.type}
              label={phoneNumber.label}
              name={phoneNumber.name}
              onChange={(e) => setPNumber(e.target.value)}
              value={pNumber}
              placeholder={phoneNumber.placeholder}
              // error={errors.phoneNumber && touched.phoneNumber}
              // success={pNumber.length > 0 && !errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              onChange={(e) => setEmail(e.target.value)}
              value={emailState}
              placeholder={email.placeholder}
              // error={errors.email && touched.email}
              // success={emailState.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SoftBox mb={1.5}>
              <SoftBox mb={2} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="2px"
                >
                  <StyleIcon fontSize="inherit" /> Tags
                </SoftTypography>
              </SoftBox>
              <Autocomplete
                className="auto-complete"
                multiple
                freeSolo={true}
                forcePopupIcon={true}
                id="tags-outlined"
                options={skills}
                clearOnEscape
                // isOptionEqualToValue={(option, value) => {
                //   console.log(option, value);
                //   return true;
                // }}
                getOptionLabel={(option) => {
                  // console.log("inside option label ------- ", option);
                  return option.tagName || option;
                }}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setSelectedTags(newValue);
                }}
                // onFocus={() => alert("Loading ... ")}
                // noOptionsText={"No Available Tags"}
                autoHighlight={true}
                filterSelectedOptions
                renderInput={(params) => {
                  params.inputProps.onKeyDown = handleKeyDown;
                  return (
                    <TextField
                      {...params}
                      placeholder="Add tags for contacts ..."
                      // margin="normal"
                      sx={{ marginTop: "-10px" }}
                      fullWidth
                    />
                  );
                }}
              ></Autocomplete>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type={info1.type}
              label={info1.label}
              name={info1.name}
              onChange={(e) => setInfo_1(e.target.value)}
              value={info_1}
              placeholder={info1.placeholder}
              // error={errors.info1 && touched.info1}
              // success={info_1.length > 0 && !errors.info1}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type={info2.type}
              label={info2.label}
              name={info2.name}
              onChange={(e) => setInfo_2(e.target.value)}
              value={info_2}
              placeholder={info2.placeholder}
              // error={errors.info2 && touched.info2}
              // success={info_1.length > 0 && !errors.info1}

              // success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type={info3.type}
              label={info3.label}
              name={info3.name}
              onChange={(e) => setInfo_3(e.target.value)}
              value={info_3}
              placeholder={info3.placeholder}
              // error={errors.info3 && touched.info3}
              // success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
