/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function Contacts({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="m6.75 7c0-1.79493 1.45507-3.25 3.25-3.25 1.7949 0 3.25 1.45507 3.25 3.25s-1.4551 3.25-3.25 3.25c-1.79493 0-3.25-1.45507-3.25-3.25zm3.25-4.75c-2.62335 0-4.75 2.12665-4.75 4.75s2.12665 4.75 4.75 4.75c.7146 0 1.3923-.1578 2.0002-.4404.6083.2826 1.2863.4404 1.9998.4404 2.6234 0 4.75-2.12665 4.75-4.75s-2.1266-4.75-4.75-4.75c-.7132 0-1.3913.15767-1.9998.44042-.6079-.28263-1.2856-.44042-2.0002-.44042zm3.4996 1.53819c.7764.84557 1.2504 1.97334 1.2504 3.21181s-.474 2.36624-1.2504 3.2118c.163.0252.3301.0382.5004.0382 1.7949 0 3.25-1.45507 3.25-3.25s-1.4551-3.25-3.25-3.25c-.1704 0-.3375.01305-.5004.03819zm-3.4996 10.96181c-2.52369 0-4.69959 1.4958-5.68651 3.6524-.20782.4542-.11338.8704.18709 1.2142.31958.3656.87302.6334 1.49942.6334h1 5 2c.6264 0 1.1798-.2678 1.4994-.6334.3005-.3438.3949-.76.1871-1.2142-.9869-2.1566-3.1628-3.6524-5.6865-3.6524zm4 7h-2-5-1c-1.03045 0-2.00599-.4337-2.62881-1.1463-.64193-.7344-.9033-1.773-.42167-2.8255 1.22208-2.6704 3.91854-4.5282 7.05048-4.5282.6918 0 1.3623.0906 2.0003.2606.6387-.1701 1.3092-.2606 1.9997-.2606 3.1319 0 5.8284 1.8578 7.0505 4.5282.4816 1.0525.2202 2.0911-.4217 2.8255-.6228.7126-1.5983 1.1463-2.6288 1.1463zm.625-6.9691c2.2541.2238 4.1571 1.6452 5.0615 3.6215.2078.4542.1134.8704-.1871 1.2142-.3196.3656-.873.6334-1.4994.6334h-1.1069c.4398-.6957.566-1.5787.1574-2.4718-.5455-1.192-1.3848-2.2221-2.4255-2.9973z"
        fill="#000000"
      />
    </svg>
  );
}

// Setting default values for the props of Contacts
Contacts.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Contacts
Contacts.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Contacts;
