import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const BASE_URL = process.env.REACT_APP_ENV_URL;

const createAPI = () => {
  const { getAccessTokenSilently } = useAuth0();
  
  const api = axios.create({
    baseURL: `${BASE_URL}`,
  });

  api.interceptors.request.use(async (config) => {
    try {
      const accessToken = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      console.error("Failed to get access token:", error);
    }
    return config;
  });

  return api;
};

export default createAPI;
