import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Auth0Provider } from "@auth0/auth0-react";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

// Redux Store
// import store from "store";
import { Provider } from "react-redux";
import store from "redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain="dev-vuedq6ap.us.auth0.com"
      clientId="1LxTCldl5UPaCXRiETwhh2wpvqTDjfMU"
      authorizationParams={{
        redirect_uri: window.location.origin + "/dashboard",
        audience: "https://dev-vuedq6ap.us.auth0.com/api/v2/",
        scope: "read:current_user update:current_user_metadata profile email",
      }}
    >
      <BrowserRouter>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </BrowserRouter>
    </Auth0Provider>
  </Provider>
);
