const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  messages: [],
  status: "idle",
  error: null,
};

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({email, api}) => {
    console.log("messages: ", email);
    const response = await api.get("/messages/user/" + email);
    return response.data;
  }
);

export const deleteMessage = createAsyncThunk(
  "message/deleteMessage",
  async (data) => {}
);

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMessages.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getMessagesStatus = (state) => state.messages.status;
export const getMessagesError = (state) => state.messages.status;
export const getMessages = (state) => state.messages.messages;

export default messagesSlice.reducer;
