import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { phoneNumber, info1, info2, info3, info4, info5 },
} = checkout;

const validations = [
  Yup.object().shape({
    [phoneNumber.name]: Yup.string(),
    [info1.name]: Yup.string().notRequired(),
    [info2.name]: Yup.string().notRequired(),
    [info3.name]: Yup.string().notRequired(),
    [info4.name]: Yup.string().notRequired(),
    [info5.name]: Yup.string().notRequired(),
  }),
];

export default validations;
