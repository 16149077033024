// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";

// Data
import dataTableData from "./data/StaticDataTable";
import { useSelector } from "react-redux";
import { getMessages } from "redux/messages/messagesSlice";
import { getMessagesStatus } from "redux/messages/messagesSlice";
import { getMessagesError } from "redux/messages/messagesSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchMessages } from "redux/messages/messagesSlice";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import MsgActionCell from "./data/ActionCell";
import Moment from "moment";
import Chat from "./data/chat";
import SoftInput from "components/SoftInput";
import SendIcon from "@mui/icons-material/Send";
import SoftButton from "components/SoftButton";
import ContactCell from "./data/ContactCell";
import createAPI from "api";

function List() {
	const api = createAPI();
  const messages = useSelector(getMessages);
  const [currentChatName, setCurrentChatName] = useState("");
  const messagesStatus = useSelector(getMessagesStatus);
  const messagesError = useSelector(getMessagesError);
  const { user, isAuthenticted, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const [selectedContact, setSelectedContact] = useState(null);
  const [table, setTable] = useState(dataTableData);
  const [okay, setOkay] = useState(false);
  const [msgValue, setMsgValue] = useState("");
  const [clicked, setClicked] = useState(false);
  const [newMsg, setNewMsg] = useState(null);

  const fillDataTable = () => {
    const data = messages;
    let arr = [];
    if (data.length) {
      data.map((message) => {
        console.log("message --------> ", message);
        const contactId = message.contactId;
        const msgObj = {
          id: (
            <ContactCell
              contactId={contactId}
            ></ContactCell>
          ),
          date: Moment(message.created).format("DD-MM-YYYY HH:mm:ss"),
          messages:
            message.content.length >= 10
              ? message.content.substring(0, 10) + " ...  "
              : message.content,
          actions: (
            <MsgActionCell
              setOkay={setOkay}
              okay={okay}
              data={message}
              setSelectedContact={setSelectedContact}
            />
          ),
        };
        arr.push(msgObj);
      });
    } else {
      arr = [
        {
          status: "empty",
        },
      ];
    }
    setTable((prev) => {
      return { ...prev, rows: [...arr] };
    });
  };

  const handleSendingMsg = async () => {
    setClicked(true);
    const data = {
      content: msgValue,
      owner: selectedContact.owner,
      deviceId: selectedContact.devicesDeviceId,
      contactsContactId: selectedContact.contactsContactId,
      phoneNumber: selectedContact.Contacts.phoneNumber,
    };
    const newMsgData = {
      text: msgValue,
      incoming: false,
      date: Moment(new Date()).format("DD-MM-YYYY HH:mm:ss"),
    };
		
		/////////////////////////////// figure it out later
    const response = await api.post("/message/sendQuickMessage", data);
		// const response = await api.get('/messages/user/' + user.email);
    if (response.status === 200 || response.status === 201) {
      setTimeout(() => {
        setClicked(false);
        setNewMsg(newMsgData);
      }, 2000);
      setTimeout(() => {
        dispatch(fetchMessages({email: user.email, api}));
      }, 11000);
    }
    setMsgValue("");
    console.log(response);
  };

  useEffect(() => {
    if (!isLoading && messagesStatus === "idle") {
      dispatch(fetchMessages({email: user.email, api}));
    }
  }, [user, messagesStatus, dispatch]);

	useEffect(() => {
		if (!isLoading) {
			dispatch(fetchMessages({email: user.email, api}));
		}
  }, []);

  useEffect(() => {
    if (messagesStatus === "succeeded") {
      fillDataTable();
    }
  }, [messagesStatus]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={okay && selectedContact && selectedContact.Contacts ? 8 : 12}
          >
            <Card sx={{ marginRight: "10px" }}>
              <SoftBox p={3} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Messages
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  List of last 100 messages sent by your devices
                </SoftTypography>
              </SoftBox>
              <DataTable
                // entriesPerPage={() => [1, 3, 5]}
                table={table}
                canSearch
              />
            </Card>
          </Grid>
          {okay && selectedContact && selectedContact.Contacts ? (
            <Card sx={{ maxHeight: "560px" }}>
              <SoftBox p={3} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {currentChatName}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Please scroll to load additional messages
                </SoftTypography>
                <Divider></Divider>
                <Chat
                  selectedContact={selectedContact}
                  setCurrentChatName={setCurrentChatName}
                  newMsg={newMsg}
                />
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  m={1}
                >
                  <SoftBox flexGrow={1}>
                    <SoftInput
                      value={msgValue}
                      placeholder="Write your message "
                      onChange={(e) => {
                        setMsgValue(e.target.value);
                      }}
                    />
                  </SoftBox>

                  <IconButton
                    size="large"
                    disabled={msgValue === "" ? true : false}
                  >
                    {clicked ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <SendIcon
                        color="info"
                        fontSize="inherit"
                        onClick={handleSendingMsg}
                      />
                    )}
                  </IconButton>
                </SoftBox>
              </SoftBox>
            </Card>
          ) : (
            ""
          )}
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default List;
