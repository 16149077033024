// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import { Socket, io } from "socket.io-client";
import { toDataURL } from "qrcode";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch } from "react-redux";
import { fetchDevices } from "redux/devices/devicesSlice";
import { useAuth0 } from "@auth0/auth0-react";
import createAPI from "api"
import { useNavigate } from "react-router";
import SoftButton from "components/SoftButton";

function DeviceQR({ socket, label = 'test', setIsDone = () => {}, deviceId = '', setOpen = () => {}, setShowQrCode = () => {}}) {
	const api = createAPI();
  const dispatch = useDispatch();
  const [qrCode, setqrCode] = useState("");
  const [ready, setReady] = useState(false);
  const [client, setClient] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const { user, isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	let device_id;

	const getPhoneNumber = async (str) => {
		try{
			if (str === 'Could not fetch the phone number of the client') {
				const { value: inputValue } = await Swal.fire({
					title: 'Oops!',
					text: 'We could not get your phone number, please fill it below',
					input: 'text',
					inputPlaceholder: 'eg. 966510901312',
					allowOutsideClick: false,
					inputValidator: (value) => {
						if (!value) {
							return 'You need to enter your phone number!';
						}
						const phoneRegex = /^[0-9]+$/;
						if (!phoneRegex.test(value)) {
								return 'Please enter a valid phone number containing only numbers!';
						}
					}
				});
				if (inputValue) {
					socket.emit('number', {deviceId: device_id ? device_id : deviceId, number: inputValue});
				}
			} else {
				try {
					await Swal.fire({
						icon: "error",
						title: "Oops",
						text: str,
						timer: 2600,
						showConfirmButton: false,
					});
					setTimeout(() => {
						navigate('/devices/devices-list');
					}, 200);
				} catch(err) {
					console.log(err.message);
				}
			}
		} catch(err) {
			console.log(err.message);
		}
	}

  useEffect(() => {
		const createDevice = async () => {
			const res = await api.get('/users/' + user.email);
			try {
				const response = await api.post('/devices/add-device', {
					userId: res.data.id,
					label: label
				});
				device_id = response.data.id;
			} catch (err) {
				await Swal.fire({
					icon: "error",
					title: "Oops",
					text: err.response.data.message,
					timer: 2600,
					showConfirmButton: false,
				});
				navigate('/dashboard');
			}
		}

		const updateDevice = async () => {
			await api.patch(`/devices`, { deviceId: deviceId, status: 1 })
		}

		if (!deviceId) createDevice();
		else updateDevice();
	
    setIsDone(true);
    socket.on("qr", async (qr) => {
      // setqrCode(qr);
      console.log("qr code  ========================> ", qr);
      toDataURL(qr, (err, url) => {
        setqrCode(url);
      });
      setReady(true);
      // setIsDone(false);
    });

    socket.on("authenticated", (str) => {
      console.log("Client is authenticated remove the QR code now ... ");
    });

    socket.on("loading_screen", (str) => {
      console.log(str);
      setScreenLoading(true);
    });

    socket.on("auth_failure", async (str) => {
      console.log("Auth Failed ......... !!!!!!!!!!!!");
			await getPhoneNumber(str)
    });

    socket.on("ready", (str) => {
      console.log("Client is ready ...");
      setClient(true);
      setIsDone(false);
      dispatch(fetchDevices({email: user.email, api}));
    });
  }, []);

  return (
    <SoftBox>
      <SoftBox lineHeight={0} sx={{ padding: "10px" }}>
        <SoftTypography variant="h5" fontWeight="bold">
          Please Scan this QR Code
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Go to WhatsApp, ⋮, Linked devices, Link a device
        </SoftTypography>
      </SoftBox>
      <SoftBox
        mt={1.625}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "360px",
        }}
      >
        {!client ? (
          !screenLoading ? (
            !ready ? (
              <SoftBox
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <SoftTypography
                  sx={{
                    width: "80%",
                    // height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px",
                    fontWeight: "200",
                    fontSize: "20px",
                  }}
                >
                  Generating QR Code ...
                </SoftTypography>
                <br />
                <Box
                  sx={{
                    width: "100%",
                    // height: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                  {/* <LinearProgress color="inherit" /> */}
                </Box>
              </SoftBox>
            ) : (
              <SoftBox
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  className=""
                  src={qrCode}
                  alt="qrcode"
                  style={{
										maxWidth: "100%",
										maxHeight: "100%",
										width: "auto",
										height: "auto",
                  }}
                />
              </SoftBox>
            )
          ) : (
            <SoftBox
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SoftTypography
                sx={{
                  width: "80%",
                  // height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "200",
                  fontSize: "20px",
                }}
              >
                Preparing device ...
              </SoftTypography>
              <br />
              <Box
                sx={{
                  width: "100%",
                  // height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            </SoftBox>
          )
        ) : (
          <SoftBox
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <SoftTypography
              sx={{
                width: "80%",
                // height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "200",
                fontSize: "20px",
              }}
            >
              Device Created Successfully
            </SoftTypography>
            <Box
              sx={{
                width: "100%",
                // height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DoneIcon sx={{ width: "40px", height: "40px" }} />
            </Box>
						{ deviceId &&
							<SoftBox marginTop={10} >
								<SoftButton variant="gradient" color="info" size="large" onClick={() => { setOpen(false); setShowQrCode(false) }}>
									Close
								</SoftButton>
							</SoftBox>
						}
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  );
}

export default DeviceQR;
